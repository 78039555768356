import { toast } from "react-toastify";
import { API } from "services/axios";
import { updateAccountPasswordFailed } from "store/actions/UpdatePasswordAction";
import { updateAccountPasswordSuccess } from "store/actions/UpdatePasswordAction";
import { updateAccountPasswordRequest } from "store/actions/UpdatePasswordAction";

export function updateAccountPassword(formData2, history2) {
  return (dispatch) => {
    dispatch(updateAccountPasswordRequest());
    API.post(
      "https://api.cadyst.ova-consulting.fr/accounts/password/change/",
      formData2
    )

      .then((response) => {
        dispatch(updateAccountPasswordSuccess(response.formData2));
        history2.push("/admin/dashboard");
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ??
            "password update failed!! Please try later"
        );

        dispatch(updateAccountPasswordFailed());
      });
  };
}
