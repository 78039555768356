import {
  LOADING_PRICES_LISTS_FAILED,
  LOADING_PRICES_LISTS_REQUEST,
  LOADING_PRICES_LISTS_SUCCESS,
  CREATE_PRICES_LIST_REQUEST,
  CREATE_PRICES_LIST_SUCCESS,
  CREATE_PRICES_LIST_FAILED,
  DELETE_PRICES_LIST_FAILED,
  DELETE_PRICES_LIST_SUCCESS,
  DELETE_PRICES_LIST_REQUEST,
  EDIT_PRICES_LIST_FAILED,
  EDIT_PRICES_LIST_REQUEST,
  EDIT_PRICES_LIST_SUCCESS,
  LOAD_PRICES_LIST_FAILED,
  LOAD_PRICES_LIST_REQUEST,
  LOAD_PRICES_LIST_SUCCESS,
} from "../actions/PriceListActions";

const initialState = {
  loading_price_lists_request: false,
  loading_price_lists_success: false,
  loading_price_lists_failed: false,
  loading_price_list_request: false,
  loading_price_list_success: false,
  loading_price_list_failed: false,
  create_price_list_request: false,
  create_price_list_success: false,
  create_price_list_failed: false,
  edit_price_list_request: false,
  edit_price_list_success: false,
  edit_price_list_failed: false,
  delete_price_list_request: false,
  delete_price_list_success: false,
  delete_price_list_failed: false,
  pricesListList: {
    count: 0,
    pages: 1,
    actualPage: 1,
    results: [],
  },
  pricesList: undefined,
};

export function PricesListReducers(state = initialState, action) {
  switch (action.type) {
    case LOADING_PRICES_LISTS_REQUEST:
      return {
        ...state,
        loading_price_lists_request: true,
        loading_price_lists_success: false,
        loading_price_lists_failed: false,
      };
    case LOADING_PRICES_LISTS_SUCCESS:
      return {
        ...state,
        loading_price_lists_request: false,
        loading_price_lists_success: true,
        loading_price_lists_failed: false,
        pricesListList: action.payload.isAdmin
          ? { ...state.pricesListList, ...action.payload.data }
          : { ...state.pricesListList, ...action.payload.data },
      };
    case LOADING_PRICES_LISTS_FAILED:
      return {
        ...state,
        loading_price_lists_request: false,
        loading_price_lists_success: false,
        loading_price_lists_failed: true,
      };
    case LOAD_PRICES_LIST_REQUEST:
      return {
        ...state,
        loading_price_list_request: true,
        loading_price_list_success: false,
        loading_price_list_failed: false,
      };
    case LOAD_PRICES_LIST_SUCCESS:
      return {
        ...state,
        loading_price_list_request: false,
        loading_price_list_success: true,
        loading_price_list_failed: false,
        price_list: action.payload,
      };
    case LOAD_PRICES_LIST_FAILED:
      return {
        ...state,
        loading_price_list_request: false,
        loading_price_list_success: false,
        loading_price_list_failed: true,
      };
    case CREATE_PRICES_LIST_REQUEST:
      return {
        ...state,
        create_price_list_request: true,
        create_price_list_success: false,
        create_price_list_failed: false,
      };
    case CREATE_PRICES_LIST_SUCCESS:
      return {
        ...state,
        create_price_list_request: false,
        create_price_list_success: true,
        create_price_list_failed: false,
        // pricesListList: {
        //     ...state.pricesListList,
        //     results: [action.payload, ...state.pricesListList.results]
        // },
      };
    case CREATE_PRICES_LIST_FAILED:
      return {
        ...state,
        create_price_list_request: false,
        create_price_list_success: false,
        create_price_list_failed: true,
      };

    case EDIT_PRICES_LIST_REQUEST:
      return {
        ...state,
        edit_price_list_request: true,
        edit_price_list_success: false,
        edit_price_list_failed: false,
      };
    case EDIT_PRICES_LIST_SUCCESS:
      return {
        ...state,
        edit_price_list_request: false,
        edit_price_list_success: true,
        edit_price_list_failed: false,
        pricesListList: {
          ...state.pricesListList,
          results: state.pricesListList.results.map((item) => {
            if (item.id === action.id) {
              item = action.payload.data;
            }

            return item;
          }),
        },
      };
    case EDIT_PRICES_LIST_FAILED:
      return {
        ...state,
        edit_price_list_request: false,
        edit_price_list_success: false,
        edit_price_list_failed: true,
      };

    case DELETE_PRICES_LIST_REQUEST:
      return {
        ...state,
        delete_price_list_request: true,
        delete_price_list_success: false,
        delete_price_list_failed: false,
      };
    case DELETE_PRICES_LIST_SUCCESS:
      return {
        ...state,
        delete_price_list_request: false,
        delete_price_list_success: true,
        delete_price_list_failed: false,
        pricesListList: {
          ...state.pricesListList,
          results: state.pricesListList.results.filter(
            (item) => item.id != action.payload.id
          ),
        },
      };
    case DELETE_PRICES_LIST_FAILED:
      return {
        ...state,
        create_price_list_request: false,
        create_price_list_success: false,
        create_price_list_failed: true,
      };

    default:
      return state;
  }
}
