export const LOADING_CLIENTS_REQUEST = "LOADING_CLIENTS_REQUEST";
export const LOADING_CLIENTS_SUCCESS = "LOADING_CLIENTS_SUCCESS";
export const LOADING_CLIENTS_FAILED = "LOADING_CLIENTS_FAILED";

export const CREATE_CLIENT_REQUEST = "CREATE_CLIENT_REQUEST";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const CREATE_CLIENT_FAILED = "CREATE_CLIENT_FAILED";

export const EDIT_CLIENT_REQUEST = "EDIT_CLIENT_REQUEST";
export const EDIT_CLIENT_SUCCESS = "EDIT_CLIENT_SUCCESS";
export const EDIT_CLIENT_FAILED = "EDIT_CLIENT_FAILED";

export const DELETE_CLIENT_REQUEST = "DELETE_CLIENT_REQUEST";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAILED = "DELETE_CLIENT_FAILED";

export const LOAD_CLIENT_REQUEST = "LOAD_ CLIENT_REQUEST";
export const LOAD_CLIENT_SUCCESS = "LOAD_CLIENT_SUCCESS";
export const LOAD_CLIENT_FAILED = "LOAD_CLIENT_FAILED";

// Addresses actions types

export const CREATE_ADDRESS_REQUEST = "CREATE_CLIENT_REQUEST";
export const CREATE_ADDRESS_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const CREATE_ADDRESS_FAILED = "CREATE_CLIENT_FAILED";

export function loadingClientsRequest() {
  return {
    type: LOADING_CLIENTS_REQUEST,
  };
}

export function loadingClientsSuccess(data, isAdmin) {
  return {
    type: LOADING_CLIENTS_SUCCESS,
    payload: { data, isAdmin },
  };
}

export function loadingClientsFailed() {
  return {
    type: LOADING_CLIENTS_FAILED,
  };
}

// Create client action

export function createClientRequest() {
  return {
    type: CREATE_CLIENT_REQUEST,
  };
}

export function createClientSuccess(data) {
  return {
    type: CREATE_CLIENT_SUCCESS,
    payload: data,
  };
}

export function createClientFailed() {
  return {
    type: CREATE_CLIENT_FAILED,
  };
}

// Edit client actions

export function editClientRequest() {
  return {
    type: EDIT_CLIENT_REQUEST,
  };
}

export function editClientSuccess(id, data) {
  return {
    type: EDIT_CLIENT_SUCCESS,
    payload: { id, data },
  };
}

export function editClientFailed() {
  return {
    type: EDIT_CLIENT_FAILED,
  };
}

// Delete Client actions

export function deleteClientRequest() {
  return {
    type: DELETE_CLIENT_REQUEST,
  };
}

export function deleteClientSuccess(id) {
  return {
    type: DELETE_CLIENT_SUCCESS,
    payload: { id: id },
  };
}

export function deleteClientFailed() {
  return {
    type: DELETE_CLIENT_FAILED,
  };
}
// Load Client actions

export function loadClientRequest() {
  return {
    type: LOAD_CLIENT_REQUEST,
  };
}

export function loadClientSuccess(client) {
  return {
    type: LOAD_CLIENT_SUCCESS,
    payload: client,
  };
}

export function loadClientFailed() {
  return {
    type: LOAD_CLIENT_FAILED,
  };
}

/* Addresses actions 

Create client action
*/

export function createAddressRequest() {
  return {
    type: CREATE_ADDRESS_REQUEST,
  };
}

export function createAddressSuccess(data) {
  return {
    type: CREATE_ADDRESS_SUCCESS,
    payload: data,
  };
}

export function createAddressFailed() {
  return {
    type: CREATE_ADDRESS_FAILED,
  };
}
