import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {
  Button,
  Box,
  LinearProgress,
  IconButton,
  TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadingProducts } from "store/effects/productsEffect";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// core components
import tableStyles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { withStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";

import Tooltip from "@material-ui/core/Tooltip";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
// import { useNavigate } from "react-router-dom";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  mlAuto: {
    marginLeft: "auto",
  },
  dFlex: {
    display: "flex",
  },
  table: {
    minWidth: 650,
  },
  textSearch: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
  },
};
const SearchTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: "white",
      borderWidth: 2,
    },
    background: '#fff',
    borderRadius: '4px',
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
    "& fieldset": {
      borderColor: "#ffffff",
    },
    "& input": {
      color: "#ffffff",
    },
  },
})(TextField);

const useStyles = makeStyles(styles);
const useStyles1 = makeStyles(tableStyles);

export default function Salers() {
  const classes = useStyles();
  const classes1 = useStyles1();
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [salers, setSalers] = useState(undefined);
  const handleSearch = (event) => {
    dispatch(loadingProducts(event.target.value ?? ""));
  };
  useEffect(() => {
    dispatch(loadingProducts(""));

    // Load salers
    API.get(URLS.USERS.SALER.LIST("")).then((response) => {
      const { data } = response;
      setSalers(data);
      setLoading(false);
    });
  }, []);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
      <Box sx={{display: 'flex'}}>
          <Box sx={{marginLeft: ''}} mb={2}>
            <Button
              variant={"contained"}
              color={"primary"}
              startIcon={<AddIcon />}
              component={Link}
              to={"/admin/saler/new"}
            >
              {t("New_Saler")}
            </Button>
          </Box>
        </Box>
        <Card>
          <CardHeader color="primary">
            <Box className={classes.dFlex}>
              <div>
                <h4 className={classes.cardTitleWhite}>{t("Salers_List")}</h4>
                <p className={classes.cardCategoryWhite}>
                  {t("The_list_of_salers_according_to_your_branch")}
                </p>
              </div>
              <div className={classes.mlAuto}>
                <SearchTextField
                  id="outlined-basic"
                  label={t("Search")}
                  variant="outlined"
                  onChange={handleSearch}
                  style={{ marginRight: "1rem", marginTop: "0px" }}
                  margin="dense"
                />
              </div>
            </Box>
          </CardHeader>
          <CardBody>
            <TableContainer>
              <Table className={classes1.table} aria-label="simple table">
                <TableHead className={classes1["primaryTableHeader"]}>
                  <TableRow className={classes1.tableHeadRow}>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      {t("Full_Name")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      {t("Phone_Number")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      Email
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      {t("Branch")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      {t("Joined_At")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      {t("Created_By")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {salers?.results.map((sale) => {
                    return (
                      <TableRow key={sale.id} className={classes1.tableBodyRow}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes1.tableCell}
                        >
                          {sale.first_name + " " + sale.last_name}
                        </TableCell>
                        <TableCell className={classes1.tableCell}>
                          {sale.phone_number}
                        </TableCell>
                        <TableCell className={classes1.tableCell}>
                          {sale.email}
                        </TableCell>
                        <TableCell className={classes1.tableCell}>
                          {sale.branch}
                        </TableCell>
                        <TableCell className={classes1.tableCell}>
                          {sale.date_joined}
                        </TableCell>
                        <TableCell className={classes1.tableCell}>
                          {sale.created_by?.first_name ??
                            "" + " " + sale.created_by?.last_name ??
                            ""}
                        </TableCell>
                        <TableCell className={classes1.tableCell}>
                          <Tooltip title="Details" arrow>
                            {/* <Link to={`/admin/saler/${sale.id}/details`}> */}
                            <Link
                              to={{
                                pathname: `/admin/saler/${sale.id}/details`,
                                state: `${sale.id}`,
                              }}
                            >
                              <IconButton>
                                <VisibilityOutlinedIcon />
                              </IconButton>
                            </Link>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {loading && <LinearProgress />}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
