import { LOADING_SALERS_REQUEST } from "store/actions/usersActions";
import { LOADING_SALERS_SUCCESS } from "store/actions/usersActions";
import { LOADING_SALERS_FAILED } from "store/actions/usersActions";

const initialState = {
  loading_salers_request: false,
  loading_salers_success: false,
  loading_salers_failed: false,
  usersList: {
    count: 0,
    pages: 1,
    actualPage: 1,
    results: [],
  },
};

export function UsersReducers(state = initialState, action) {
  switch (action.type) {
    case LOADING_SALERS_REQUEST:
      return {
        ...state,
        loading_salers_request: true,
        loading_salers_success: false,
        loading_salers_failed: false,
      };
    case LOADING_SALERS_SUCCESS:
      return {
        ...state,
        loading_salers_request: false,
        loading_salers_success: true,
        loading_salers_failed: false,
        usersList: { ...state.results, ...action.payload },
      };
    case LOADING_SALERS_FAILED:
      return {
        ...state,
        loading_salers_request: false,
        loading_salers_success: false,
        loading_salers_failed: true,
      };
    default:
      return state;
  }
}
