import { LOADING_SALER_PROFILE_FAILED } from "store/actions/salerProfileAction";
import { EDIT_SALER_PROFILE_REQUEST } from "store/actions/salerProfileAction";
import { EDIT_SALER_PROFILE_FAILED } from "store/actions/salerProfileAction";
import { LOADING_SALER_PROFILE_REQUEST } from "store/actions/salerProfileAction";
import { EDIT_SALER_PROFILE_SUCCESS } from "store/actions/salerProfileAction";
import { LOADING_SALER_PROFILE_SUCCESS } from "store/actions/salerProfileAction";

const initialState = {
  loading_saler_profile_request: false,
  loading_saler_profile_success: false,
  loading_saler_profile_failed: false,
  user: undefined,
};

const initialState2 = {
  edit_saler_profile_request: false,
  edit_saler_profile_success: false,
  edit_saler_profile_failed: false,
  user2: undefined,
};

export function SalerProfileReducers(state = initialState, action) {
  switch (action.type) {
    case LOADING_SALER_PROFILE_REQUEST:
      return {
        ...state,
        loading_saler_profile_request: true,
        loading_saler_profile_success: false,
        loading_saler_profile_failed: false,
      };
    case LOADING_SALER_PROFILE_SUCCESS:
      return {
        ...state,
        loading_saler_profile_request: false,
        loading_saler_profile_success: true,
        loading_saler_profile_failed: false,
        user: action.payload,
      };
    case LOADING_SALER_PROFILE_FAILED:
      return {
        ...state,
        loading_saler_profile_request: false,
        loading_saler_profile_success: false,
        loading_saler_profile_failed: true,
      };
    default:
      return state;
  }
}

export function editSalerProfileReducers(state = initialState2, action) {
  switch (action.type) {
    case EDIT_SALER_PROFILE_REQUEST:
      return {
        ...state,
        edit_saler_profile_request: true,
        edit_saler_profile_success: false,
        edit_saler_profile_failed: false,
      };
    case EDIT_SALER_PROFILE_SUCCESS:
      return {
        ...state,
        edit_saler_profile_request: false,
        edit_saler_profile_success: true,
        edit_saler_profile_failed: false,
        user2: action.payload,
      };
    case EDIT_SALER_PROFILE_FAILED:
      return {
        ...state,
        edit_saler_profile_request: false,
        edit_saler_profile_success: false,
        edit_saler_profile_failed: true,
      };
    default:
      return state;
  }
}
