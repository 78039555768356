import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#093A63",
    },
    secondary: {
      main: "#7EA6BF",
    },
  },
  typography: {
    button: {
      // fontStyle: 'italic',
      color: "#fff",
      "& .MuiButton-label": {
        color: "#fff",
      },
    },
  },
  components: {
    MuiInputBase: {
      overrides: {
        root: {
          backgroundColor: "#fff",
          "&.Mui-disabled": {
            backgroundColor: "#e4e4e4",
          },
        },
      },
    },
  },
});

export { theme };
