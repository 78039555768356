// core components
// import GridItem from "components/Grid/GridItem.js";
import React from "react";
import { Button, Container, Grid, makeStyles } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
// Formik form
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody";
import { useDispatch, useSelector } from "react-redux";
import { createPayment } from "store/effects/paymentEffects";
import { useTranslation } from "react-i18next";

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

const validationSchema = (t) =>
  yup.object({
    label: yup
      .string("Enter the payment's method name")
      .required(t("payments_method_name_is_required")),
  });

export default function NewPaymentMethods(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { create_payment_request } = useSelector((state) => state.payments);
  const formik = useFormik({
    initialValues: {
      label: "",
    },
    validationSchema: validationSchema(t),
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
      dispatch(createPayment(values, props.history));
    },
  });
  return (
    <GridContainer>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{t("New_Payment_Method")}</h4>
          <p className={classes.cardCategoryWhite}>
            {t("You_are_creating_a_new_payment_method")}
          </p>
        </CardHeader>
        <CardBody>
          <Container>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="label"
                    name="label"
                    label={t("Payment_method_name")}
                    variant="outlined"
                    value={formik.values.label}
                    onChange={formik.handleChange}
                    error={formik.touched.label && Boolean(formik.errors.label)}
                    helperText={formik.touched.label && formik.errors.label}
                  />
                </Grid>
                <Grid item md={12}>
                  <Button
                    disabled={create_payment_request}
                    type={"submit"}
                    variant="contained"
                    color="primary"
                  >
                    {t("create_method")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
