/*eslint-disable*/
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import { ExpandLess, ExpandMore, StarBorder } from "@material-ui/icons";
import { Collapse, Typography } from "@material-ui/core";
import { ListItemIcon } from "@material-ui/core";
import { useTranslation } from "react-i18next";
// import Ova from "../../assets/img/Ova2.png";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  let location = useLocation();
  const [openCollapse, setOpenCollapse] = useState("");
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return location.pathname === routeName;
  }
  function collapseNavItem(routeName) {
    return location.pathname.includes(routeName);
  }
  function includesPathname(routeName) {
    return location.pathname.includes(routeName);
  }
  const { color, logo, image, logoText, routes } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        var activePro = " ";
        var listItemClasses;
        if (prop.path === "/upgrade-to-pro") {
          activePro = classes.activePro + " ";
          listItemClasses = classNames({
            [" " + classes[color]]: true,
          });
        } else {
          listItemClasses = classNames({
            [" " + classes[color]]: includesPathname(prop.layout + prop.path),
          });
        }
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
        });
        const subMenuFont = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
        });
        return (
          <>
            <NavLink
              to={prop.layout + prop.path}
              className={activePro + classes.item}
              activeClassName="active"
              key={key + "naclink"}
              // isActive={() => includesPathname(prop.layout + prop.path)}
              // isActive={true}
            >
              <ListItem button className={classes.itemLink + listItemClasses}>
                {typeof prop.icon === "string" ? (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  >
                    {prop.icon}
                  </Icon>
                ) : (
                  <prop.icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  />
                )}
                <ListItemText
                  primary={props.rtlActive ? prop.rtlName : prop.name}
                  className={classNames(classes.itemText, whiteFontClasses, {
                    [classes.itemTextRTL]: props.rtlActive,
                  })}
                  disableTypography={true}
                />
                <span className={classes.collapseIcons}>
                  {collapseNavItem(prop.layout + prop.path) && prop.children ? (
                    <ExpandLess />
                  ) : !collapseNavItem(prop.layout + prop.path) &&
                    prop.children ? (
                    <ExpandMore />
                  ) : null}
                </span>
              </ListItem>
            </NavLink>
            {collapseNavItem(prop.layout + prop.path) && prop.children && (
              <Collapse
                key={key + "collapse"}
                in={
                  collapseNavItem(prop.layout + prop.path) &&
                  prop.children.length > 0
                }
                timeout="auto"
                unmountOnExit
              >
                <List component="div" >
                  {prop.children.map((childItem, index) => {
                    return (
                      <NavLink
                        to={childItem.layout + childItem.path}
                        className={classNames(
                          activePro + classes.itemNested,
                          classes.nested,
                          classes.item
                        )}
                        activeClassName="active"
                        key={index + "naclink"}
                      >
                        <ListItem
                          button
                          className={classNames(
                            classes.itemText,
                            classes.submenu,
                            {
                              [classes.activeSubmenu]: activeRoute(
                                childItem.layout + childItem.path
                              ),
                            }
                          )}
                        >
                          {typeof childItem.icon === "string" ? (
                            <Icon
                              className={classNames(
                                classes.itemIcon,
                                whiteFontClasses,
                                {
                                  [classes.itemIconRTL]: props.rtlActive,
                                }
                              )}
                            >
                              {childItem.icon}
                            </Icon>
                          ) : (
                            <prop.icon
                              className={classNames(
                                classes.itemIcon,
                                whiteFontClasses,
                                {
                                  [classes.itemIconRTL]: props.rtlActive,
                                }
                              )}
                            />
                          )}
                          <ListItemText
                            primary={
                              props.rtlActive
                                ? childItem.rtlName
                                : childItem.name
                            }
                            color="primary"
                            className={classNames(
                              subMenuFont,
                              {
                                [classes.itemTextRTL]: props.rtlActive,
                              },
                              classes.itemText
                            )}
                            disableTypography={true}
                          />
                        </ListItem>
                      </NavLink>
                    );
                  })}
                </List>
              </Collapse>
            )}
          </>
        );
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a
        href="/"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive,
        })}
        target="_blank"
      >
      
        {/* <img src={Ova} alt="logo" className={classes.img} style={{width:"130px"}} /> */}
        
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        <span>{logoText}</span>
      </a>
    </div>
  );
  useEffect(() => {
    setOpenCollapse(location.pathname);
  }, []);
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
