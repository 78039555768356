export default {
  translation: {
    "shared.french": "Francais",
    "shared.english": "Anglais",
    "shared.internet_connexion_error": "Vérifiez votre connexion Internet!",
    visits_list: "Liste des visites",
    The_list_of_visits_according_to_your_branch:
      "Liste des visites en fonction de votre branche",
    Edit_Profile: "Modifier le Profil",
    Complete_your_profile: "Completer votre profil",
    Company: "Entreprise",
    Phone_Number: "Numéro de téléphone",
    First_Name: "Prénom",
    Last_Name: "Nom",
    Upload_an_image: "Telecharger une image",
    Update_Profile: "Mettre a jour le profil",
    Preview: "Aperçu",
    Image_should_be_lesser_or_equal_to_200_mb:
      "L'image doit etre inferieur ou egale a 200 Mo",
    enter_number_lesser_than_10_digits:
      "saisir un nombre inférieur à 10 chiffres",
    Invalid_email: "email Invalide",
    file_too_large: "fichier volumineux",
    Object: "Objet",
    Saler: "Vendeur",
    Address: "Adresse",
    Is_Completed: "Terminée",
    Search: "Rechercher",
    Full_Name: "Nom complet",
    Branch: "Branche",
    Created_By: "Créé par",
    Joined_At: "Date d'adhésion",
    The_list_of_salers_according_to_your_branch:
      "Liste des vendeurs en fonction de votre branche",
    Salers_List: "Liste des vendeurs",
    New_Saler: "Nouveau vendeur",
    Create_Saler: "Créer un vendeur",
    Inventories: "Inventaires",
    Previous_Quantity: "Quantité précédente",
    Current_Quantity: "Quantité actuelle",
    Product: "Produit",
    Visits_List: "Liste des visites",
    The_list_of_salers_Visits_according_to_your_branch:
      "Liste des visites des vendeurs en fonction de votre branche",
    New_Visit: "Nouvelle visit",
    You_are_creating_a_new_saler_profile:
      "Vous créez un nouveau profile pour vendeur",
    Select_An_Address: "choisir une adresse",
    Visit_Date: "Date de visite",
    Visit_Object: "Objet de la visite",
    Managed_By: "Dirigé par",
    Pnz_client_status: "Statut du client Pnz",
    Status: "Statut",
    Limit: "Plafond",
    New_Client: "Nouveau client",
    New_Address: "Nouvelle adresse",
    The_list_of_clients_according_to_your_branch:
      "Liste des clients en fonction de votre branche",
    delete_message_warning:
      "Vous vous apprêtez a suprimer ce moyen de payment. Cette action serra irreversible!!",
    Are_you_sure: "Etes vous sure?",
    Delete_validation: "Oui supprimer",
    Clients_List: "Liste des clients",
    New_Address_caps: "Nouvelle adresse",
    Town: "Ville",
    You_are_creating_a_client_address: "Creation d'une adresse client",
    Address_Type: "Type d'adresse",
    Select_a_Client: "Choisir un client *",
    Address_Image: "Image de l'adresse",
    Create_address: "Créer l'adresse",
    Select_a_Saler: "Choisir un vendeur *",
    Client_contract: "Contrat du client",
    Add_new_client: "Ajouter un nouveau client",
    New_client: "Nouveau client",
    You_are_creating_a_client: "Creation d'un client",
    Enter_the_clients_first_name: "Entrer le prenom du client",
    First_name_is_required: "Prenom obligatoire",
    Enter_the_clients_last_name: "Entrer le nom du client",
    Last_name_is_required: "Nom obligatoire",
    Enter_the_clients_reference: "Entrer la reference du client",
    The_clients_reference_is_required: "Reference du client obligatoire",
    Enter_clients_email: "Entrer l'email du client",
    The_clients_email_is_required: "Email du client obligatoire",
    Enter_clients_phone_number: "Entrer le numero du client",
    The_clients_phone_number_is_required: "Numero du client obligatoire",
    Enter_clients_phone_number_2: "Entrer le second numero",
    Enter_clients_region: "Entrer la region du client",
    The_clients_region_is_required: "la region du client est obligatoire",
    Enter_panzani_clients_status: "Entrer le statut du client panzani",
    The_panzani_clients_status_is_required:
      "le status panzani du client est obligatoire",
    Enter_clients_status: "Entrer le statut du client",
    The_clients_status_is_required: "statut du client requis",
    Enter_clients_plafond: "Entrer le plafond du client",
    The_clients_plafond_is_required: "Plafond du client requis",
    Payments_list: "Liste des modes de paiement",
    The_list_of_payments_according_to_your_branch:
      "Liste des modes de paiement en function de votre branche",
    New_Payment_Method: "Nouveau mode de paiement",
    Payment_name: "Nom du paiement",
    You_are_creating_a_new_payment_method:
      "Creation d'un nouveau mode de paiement",
    Payment_method_name: "Nom du mode de paiement",
    payments_method_name_is_required: "Nom du mode de paiement requis",
    create_method: "Creer un mode",
    Prices_list: "Liste des prix",
    Customers_prices_list_according_to_your_branch:
      "Liste des prix des clients en function de votre branche",
    New_price_list: "Nouvelle liste de prix",
    Created_at: "Créé le",
    Price: "Prix",
    Currency: "Monnaie",
    Updated_at: "Mise a jour le",
    The_prices_description_is_required: "Description obligatoire",
    The_Formats_price_is_required: "Prix par defaut du format obligatoire",
    You_are_creating_a_price_list: "Creation d'une liste de prix",
    Select_a_client: "Choisir un client",
    Select_a_format: "Choisir un format",
    save_the_price: "Enregistrer le prix",
    The_list_of_products_according_to_your_branch:
      "Liste de produits en fonction de votre branche",
    Products_list: "Liste de Produits",
    New_Format: "Nouveau Format",
    New_Product: "Nouveau Produit",
    Default_Price: "Prix par defaut",
    You_are_creating_a_new_product: "Creation d'un nouveau produit",
    Products_Name: "Nom du produit",
    Products_reference: "Reference du produit",
    Add_new_product: "Ajouter un nouveau produit",
    Products_description: "Description du produit",
    The_Products_reference_is_required: "reference du produit obligatoire",
    Formats_name_is_required: "Nom du format obligatoire",
    The_Formats_reference_is_required: "reference du format obligatoire",
    Formats_label: "Nom du format",
    Formats_reference: "Reference du format",
    Formats_default_price: "Prix par defaut du format",
    Select_a_product: "Choisir un produit",
    Addresses: "Adresses",
    Orders_list: "Liste des commandes",
    The_list_of_orders_according_to_your_branch:
      "Liste des commandes en fonction de votre branche",
    Price_before_tax: "Prix hors taxe",
    Price_after_tax: "Prix toutes taxes comprises",
    Payment_method: "Mode de paiement",
    Edited_at: "Modifié le",
    Unit_price: "Prix unitaire",
    Total_price: "Prix total",
    Quantity: "Quantité",
    Orders: "Commandes",
    Portfolio: "Portefeuille",
    Blog: "Blogue",
    Home: "Acceuil",
    Dashboard: "Tableau de bord",
    Products: "Produits",
    Payments: "Paiements",
    user_profile: "Profile de l'utilisateur",
    Salers: "Vendeurs",
    Salers_list: "Liste des vendeurs",
    Clients_Addresses: "Adresses des clients",
    Clients_list: "Liste de clients",
    New_Prices_List: "Nouvelle liste des prix",
    New_payment_method: "Nouveau mode de payment",
    Payment_methods: "Modes de paiement",
    Visits: "Visites",
    Confirm_Password: "Confirmer le mot de passe",
    Old_Password: "Ancien mot de passe",
    New_Password: "Nouveau mot de passe",
    Update_Password: "Mettre a jour le mot de passe",
    Confirm_New_Password: "Confirmer nouveau mot de passe",
    Password_should_be_at_least_8_characters:
      "le mot de passe doit compter un minimum de 8 caractères",
    password_must_contain_at_least_1_lower_case_letter:
      "Mot de passe doit contenir au moins une minuscule",
    password_must_contain_at_least_1_upper_case_letter:
      "Mot de passe doit contenir au moins une majuscule",
    password_must_contain_at_least_1_number:
      "Mot de passe doit contenir au moins un chiffre",
    password_must_contain_at_least_1_special_character:
      "Mot de passe doit contenir au moins un caractère spécial",
    New_Password_is_required: "Nouveau mot de passe requis",
    Please_confirm_password: "veuillez confirmer votre mot de passe",
    Passwords_must_match:
      "Mot de passe doit etre identique au champ 'nouveau mot de passe'",
    Password_is_required: "Mot de passe requis",
  },
};
