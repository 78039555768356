// core components
// import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { Button, Container, Grid, makeStyles } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
// Formik form
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody";
import { useSelector } from "react-redux";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { toast } from "react-toastify";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },

  visitField: {
    // paddingLeft: "80px !important",
    background: "red",
    display: "flex",
    textAlign: "center",
  },
};

const useStyles = makeStyles(styles);
const validationSchema = yup.object({
  object: yup.string("Object of the visit").required("This field is required"),
  visit_date: yup.string("Visit date").required("This field is required"),
  description: yup
    .string("Visit description")
    .required("This field is required"),
});

export default function NewVisit() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth.auth);
  const [loadingAddresses, setLoadingAddresses] = useState(true);
  const [isCreating, setIscreating] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [address, setAddress] = useState();
  const [search, setSearch] = useState("");
  const formik = useFormik({
    initialValues: {
      object: "",
      visit_date: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIscreating(true);
      if (address) {
        const datas = {
          ...values,
          created_by: user.id,
          address: address?.id,
        };
        console.log(JSON.stringify(datas, null, 2));
        API.post(URLS.VISITS.CREATE, datas)
          .then((resp) => {
            const { data } = resp;
            console.log(data);
            toast.success("Visit successfully created");
            setIscreating(false);
          })
          .catch((error) => {
            toast.error(
              error.response.data.detail ?? "Saler registration error!!"
            );
            setIscreating(false);
          });
      }
    },
  });
  useEffect(() => {
    API.get(URLS.USERS.CLIENTS.ADDRESSES.LIST(search)).then((response) => {
      const { data } = response;
      setLoadingAddresses(false);
      setAddresses(data.results);
    });
  }, [search]);
  return (
    <GridContainer>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{t("New_Saler")}</h4>
          <p className={classes.cardCategoryWhite}>
            {t("You_are_creating_a_new_saler_profile")}
          </p>
        </CardHeader>
        <CardBody>
          <Container>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="object"
                    name="object"
                    label={t("Visit_Object")}
                    variant="outlined"
                    value={formik.values.object}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.object && Boolean(formik.errors.object)
                    }
                    helperText={formik.touched.object && formik.errors.object}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    // className={classes.visitField}
                    fullWidth
                    id="visit_date"
                    name="visit_date"
                    label={t("Visit_Date")}
                    type={"date"}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formik.values.date}
                    onChange={formik.handleChange}
                    error={formik.touched.date && Boolean(formik.errors.date)}
                    helperText={formik.touched.date && formik.errors.date}
                  />
                </Grid>
                <Grid item md={6}>
                  <Autocomplete
                    value={address}
                    onChange={(event, newValue) => {
                      setAddress(newValue);
                    }}
                    loading={loadingAddresses}
                    onInputChange={(event, newInputValue) => {
                      setSearch(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={addresses}
                    // style={{ width: 300 }}
                    getOptionLabel={(option) =>
                      `${
                        option.client.first_name +
                        " " +
                        option.client.last_name +
                        " (" +
                        option.type +
                        ")"
                      }`
                    }
                    renderOption={(option) => (
                      <React.Fragment>
                        {" "}
                        {option.client.first_name +
                          " " +
                          option.client.last_name +
                          "(" +
                          option.type +
                          ")"}{" "}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Select_An_Address")}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Autocomplete
                    value={address}
                    onChange={(event, newValue) => {
                      setAddress(newValue);
                    }}
                    loading={loadingAddresses}
                    onInputChange={(event, newInputValue) => {
                      setSearch(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={addresses}
                    // style={{ width: 300 }}
                    getOptionLabel={(option) =>
                      `${
                        option.client.first_name +
                        " " +
                        option.client.last_name +
                        " (" +
                        option.type +
                        ")"
                      }`
                    }
                    renderOption={(option) => (
                      <React.Fragment>
                        {" "}
                        {option.client.first_name +
                          " " +
                          option.client.last_name +
                          "(" +
                          option.type +
                          ")"}{" "}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select an address *"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="description"
                    name="description"
                    label="Description"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </Grid>
                <Grid item md={6}></Grid>
                <Grid item md={12}>
                  <Button
                    disabled={isCreating}
                    type={"submit"}
                    variant="contained"
                    color="primary"
                  >
                    Create visit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
