// users list  actions

// export const EDIT_USERS_REQUEST = "EDIT_USERS_REQUEST";
// export const EDIT_USERS_SUCCESS = "EDIT_USERS_SUCCESS";
// export const EDIT_USERS_FAILED = "EDIT_USERS_FAILED";

// export const DELETE_USERS_REQUEST = "DELETE_USERS_REQUEST";
// export const DELETE_USERS_SUCCESS = "DELETE_USERS_SUCCESS";
// export const DELETE_USERS_FAILED = "DELETE_USERS_FAILED";

export const LOAD_USERSLIST_REQUEST = "LOAD_USERSLIST_REQUEST";
export const LOAD_USERSLIST_SUCCESS = "LOAD_USERSLIST_SUCCESS";
export const LOAD_USERSLIST_FAILED = "LOAD_USERSLIST_FAILED";

// Edit users actions

// export function editUsersRequest() {
//   return {
//     type: EDIT_USERS_REQUEST,
//   };
// }

// export function editUsersSuccess(id, data) {
//   return {
//     type: EDIT_USERS_SUCCESS,
//     payload: { id, data },
//   };
// }

// export function editUsersFailed() {
//   return {
//     type: EDIT_USERS_FAILED,
//   };
// }

// Delete Users

// export function deleteUsersRequest() {
//   return {
//     type: DELETE_USERS_REQUEST,
//   };
// }

// export function deleteUsersSuccess(id) {
//   return {
//     type: DELETE_USERS_SUCCESS,
//     payload: { id: id },
//   };
// }

// export function deleteUsersFailed() {
//   return {
//     type: DELETE_USERS_FAILED,
//   };
// }
// Load Users actions

export function loadUsersRequest() {
  return {
    type: LOAD_USERSLIST_REQUEST,
  };
}

export function loadUsersSuccess(data, stat) {
  return {
    type: LOAD_USERSLIST_SUCCESS,
    payload: { data, stat },
  };
}

export function loadUsersFailed() {
  return {
    type: LOAD_USERSLIST_FAILED,
  };
}
