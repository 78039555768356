// core components
// import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
// Formik form
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody";
import { useDispatch, useSelector } from "react-redux";
import { loadingProducts } from "store/effects/productsEffect";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFormat } from "store/effects/productsEffect";
import { useTranslation } from "react-i18next";

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  gridPreviewWrapper: {
    position: "relative",
  },
  previewWrapper: {
    position: "absolute",
    left: "190px",
    top: "13px",
  },
  preview: {
    width: "80px",
    height: "80px",
  },
};

const useStyles = makeStyles(styles);
const validationSchema = (t) =>
  yup.object({
    label: yup
      .string("Enter the product's format name")
      .required(t("Formats_name_is_required")),
    reference: yup
      .string("Enter the Format's reference")
      .required(t("The_Formats_reference_is_required")),
    price: yup
      .number("Enter a Format's valid price")
      .required(t("The_Formats_price_is_required")),
  });

export default function NewFormat(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    productsList: { results },
    loading_products_request,
    create_format_request,
  } = useSelector((state) => state.products);
  const [value, setValue] = useState();
  const [image, setImage] = useState();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      label: "",
      reference: "",
      price: "",
    },
    validationSchema: validationSchema(t),
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
      let formData = new FormData();
      formData.append("label", values.label);
      formData.append("reference", values.reference);
      formData.append("price", values.price);
      if (image) {
        formData.append("image", image);
      }
      if (value) {
        formData.append("product", value.id);
      }
      dispatch(createFormat(formData, props.history));
    },
  });
  const handleInputImage = (event) => {
    const files = event.target.files[0];
    setImage(URL.createObjectURL(files));
  };
  const handleSearch = (value) => {
    dispatch(loadingProducts(value ?? ""));
  };
  useEffect(() => {
    dispatch(loadingProducts(""));
  }, []);
  return (
    <GridContainer>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>New product&apos;s format</h4>
          <p className={classes.cardCategoryWhite}>
            You are creating a product format
          </p>
        </CardHeader>
        <CardBody>
          <Container>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="label"
                    name="label"
                    label={t("Formats_label")}
                    variant="outlined"
                    value={formik.values.label}
                    onChange={formik.handleChange}
                    error={formik.touched.label && Boolean(formik.errors.label)}
                    helperText={formik.touched.label && formik.errors.label}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="reference"
                    name="reference"
                    label={t("Formats_reference")}
                    variant="outlined"
                    value={formik.values.reference}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.reference &&
                      Boolean(formik.errors.reference)
                    }
                    helperText={
                      formik.touched.reference && formik.errors.reference
                    }
                  />
                </Grid>
                <Grid item md={6}>
                  <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                      console.log("newValue ========>", newValue);
                    }}
                    loading={loading_products_request}
                    // inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      handleSearch(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={results}
                    // style={{ width: 300 }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option) => (
                      <React.Fragment> {option.name} </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Select_a_product")}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="price"
                    type={"number"}
                    name="price"
                    label={t("Formats_default_price")}
                    variant="outlined"
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    helperText={formik.touched.price && formik.errors.price}
                  />
                </Grid>
                <Grid className={classes.gridPreviewWrapper} item md={12}>
                  <input
                    accept="image/*"
                    hidden
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleInputImage}
                  />
                  <label htmlFor="contained-button-file">
                    <Button variant="outlined" color="primary" component="span">
                      {t("Upload_an_image")}
                    </Button>
                  </label>
                  {/* {image && <p>{image.name}</p>} */}
                  {image && (
                    <div className={classes.previewWrapper}>
                      <img className={classes.preview} src={image} alt="tof" />
                    </div>
                  )}
                </Grid>
                <Grid item>
                  <Button
                    disabled={create_format_request}
                    type={"submit"}
                    variant="contained"
                    color="primary"
                  >
                    {t("Add_new_product")}{" "}
                    {create_format_request && <CircularProgress size={14} />}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
