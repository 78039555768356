import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import unknownPerson from "assets/img/faces/unknown-person.jpeg";
// import { useFormik, validateYupSchema } from "formik";
import * as Yup from "yup";
import YupPassword from "yup-password";
YupPassword(Yup);
// import "yup-phone";

// import avatar from "assets/img/faces/marc.jpg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getLoggedUser } from "store/effects/loggedUser";
import { CircularProgress, LinearProgress, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { editLoggedUser } from "store/effects/loggedUser";
// import { URLS } from "services/urls/urls";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { URLS } from "services/urls/urls";
import { updateAccountPassword } from "store/effects/updatePasswordEffect";

// import { URLS } from "services/urls/urls";
// import { string } from "prop-types";
// import { red } from "@material-ui/core/colors";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardTitleBlack: {
    color: "white",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    textAlign: "center",
    // textDecoration: "underline",
    background: "#093A63",
    // background: "lightgrey",
    width: "100%",
    // margin: "auto",
    padding: "10px 0",
  },
  topGridContainer: {
    marginBottom: "30px",
  },
  preview2: {
    width: "100px",
    height: "100px",
    // borderRadius: "50%",
  },
  previewWrapper2: {
    position: "absolute",
    left: "25%",
    top: "82%",
  },
  hr: {
    background: "lightgrey",
    height: "2px",
    border: "none",
    width: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  btn: {
    margin: "auto",
  },
  passwordField: {
    width: "100%",
    "& label": { fontSize: "14px" },
  },
  fullWidthField: { width: "100%" },
};

const useStyles = makeStyles(styles);

const validationSchema = (t) =>
  Yup.object({
    phoneNumber: Yup.string().max(10, t("enter_number_lesser_than_10_digits")),
    email: Yup.string().email(t("Invalid_email")),
  });

const validationSchema2 = (t) =>
  Yup.object({
    old_password: Yup.string().required(t("Password_is_required")),
    new_password: Yup.string()
      .min(8, t("Password_should_be_at_least_8_characters"))
      .minLowercase(1, t("password_must_contain_at_least_1_lower_case_letter"))
      .minUppercase(1, t("password_must_contain_at_least_1_upper_case_letter"))
      .minNumbers(1, t("password_must_contain_at_least_1_number"))
      .minSymbols(1, t("password_must_contain_at_least_1_special_character"))
      .required(t("New_Password_is_required")),
    confirm_new_password: Yup.string()
      .required(t("Please_confirm_password"))
      .oneOf([Yup.ref("new_password"), null], t("Passwords_must_match")),
  });

export default function UserProfile() {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    user,
    loading_user_success,
    loading_user_request,
    edit_logged_user_request,
    edit_logged_user_failed,
  } = useSelector((state) => state.loggedUser);

  const {
    data,
    update_account_password_request,
    update_account_password_success,
    // update_account_password_failed,
  } = useSelector((state) => state.updadePassword);

  const [imgStatus, setImgStatus] = useState("");
  const [imgDisplay, setImgDisplay] = useState();

  const [image, setImage] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  const history2 = useHistory();

  const formik = useFormik({
    initialValues: {
      id: "",
      company: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      image: "",
    },
    validationSchema: validationSchema(t),
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
      let formData = new FormData();
      formData.append("first_name", values.firstName);
      formData.append("last_name", values.lastName);
      formData.append("email", values.email);
      formData.append("phone_number", values.phoneNumber);

      if (typeof image !== "string") {
        formData.append("image", image);
      }

      dispatch(editLoggedUser(user.id, formData, history));
    },
  });

  const formik2 = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_new_password: "",
    },
    validationSchema: validationSchema2(t),
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
      let formData2 = new FormData();
      formData2.append("old_password", values.old_password);
      formData2.append("new_password", values.new_password);
      // formData2.append("confirm_new_password", values.confirm_new_password);

      // if (typeof image !== "string") {
      //   formData.append("image", image);
      // }

      dispatch(updateAccountPassword(formData2, history2));
    },
  });

  // formik handle image
  const handleImage2 = (e) => {
    const files = e.target.files[0];
    if (files) {
      if (files.size <= 2000000) {
        setImage(files);

        // URL.createObjectURL(files)
        setImgDisplay(URL.createObjectURL(e.target.files[0]));
        setImgStatus("");
      } else {
        setImgStatus(t("file_too_large"));
      }
    }
  };

  useEffect(() => {
    dispatch(getLoggedUser());
  }, []);

  useEffect(() => {
    if (loading_user_success) {
      formik.setFieldValue("company", user.branch);
      formik.setFieldValue("email", user.email);
      formik.setFieldValue("lastName", user.last_name);
      formik.setFieldValue("firstName", user.first_name);
      formik.setFieldValue("phoneNumber", user.phone_number);
    }
  }, [loading_user_success]);

  useEffect(() => {
    // console.log("user =============> ", formik.values);
  }, []);

  return (
    <div>
      {loading_user_request && <LinearProgress />}
      {loading_user_success && (
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{t("Edit_Profile")}</h4>
                <p className={classes.cardCategoryWhite}>
                  {t("Complete_your_profile")}
                </p>
              </CardHeader>
              <form onSubmit={formik.handleSubmit}>
                <CardBody>
                  <GridContainer className={classes.topGridContainer}>
                    <GridItem xs={12} sm={12} md={4}>
                      {/* <CustomInput
                    labelText="Company"
                    id="company-disabled"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: true,
                    }}
                  /> */}

                      <TextField
                        disabled
                        id="standard-required"
                        className={classes.fullWidthField}
                        label={t("Company")}
                        // value={logged.company}
                        value={formik.values.company}
                        error={
                          formik.touched.description &&
                          Boolean(formik.errors.description)
                        }
                        helperText={
                          formik.touched.description &&
                          formik.errors.description
                        }
                        variant="standard"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      {/* <CustomInput
                    labelText="Username"
                    id="username"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  /> */}

                      <TextField
                        // id="outlined-number"
                        id="input1"
                        label={t("Phone_Number")}
                        className={classes.fullWidthField}
                        name="phoneNumber"
                        type="number"
                        maxLenght={10}
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.phoneNumber &&
                          Boolean(formik.errors.phoneNumber)
                        }
                        helperText={
                          formik.touched.phoneNumber &&
                          formik.errors.phoneNumber
                        }
                      />
                      <p> {console.log(edit_logged_user_failed)} </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      {/* <CustomInput
                    labelText="email"
                    id="email-address"
                    value={logged.email}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  /> */}

                      <TextField
                        id="outlined-required"
                        label="email"
                        name="email"
                        className={classes.fullWidthField}
                        type="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                        variant="standard"
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      {/* <CustomInput
                    labelText="First Name"
                    id="first-name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  /> */}

                      <TextField
                        id="outlined-required"
                        label={t("First_Name")}
                        name="firstName"
                        className={classes.fullWidthField}
                        // onChange={(e) =>
                        //   setLogged({ ...logged, firstName: e.target.value })
                        // }
                        // value={logged.firstName}
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        variant="standard"
                        error={
                          formik.touched.description &&
                          Boolean(formik.errors.description)
                        }
                        helperText={
                          formik.touched.description &&
                          formik.errors.description
                        }
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      {/* <CustomInput
                    labelText="Last Name"
                    id="last-name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  /> */}

                      <TextField
                        id="outlined-required"
                        label={t("Last_Name")}
                        name="lastName"
                        className={classes.fullWidthField}
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        variant="standard"
                        error={
                          formik.touched.description &&
                          Boolean(formik.errors.description)
                        }
                        helperText={
                          formik.touched.description &&
                          formik.errors.description
                        }
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      {/* <CustomInput
                    labelText="City"
                    id="city"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  /> */}
                      <input
                        accept="image/*"
                        hidden
                        name="image"
                        id="contained-button-file"
                        type="file"
                        onChange={handleImage2}
                        value={formik.values.image}
                      />

                      <label htmlFor="contained-button-file">
                        <Button
                          variant="outlined"
                          color="secondary"
                          component="span"
                        >
                          {t("Upload_an_image")}
                        </Button>
                      </label>
                      <p>{t("Image_should_be_lesser_or_equal_to_200_mb")}</p>
                      {imgStatus && (
                        <span style={{ color: "red" }}>{imgStatus}</span>
                      )}
                      {imgDisplay && (
                        <div className={classes.previewWrapper2}>
                          <img
                            className={classes.preview2}
                            src={imgDisplay}
                            alt="preview"
                          />
                          <p style={{ margin: "0", textAlign: "center" }}>
                            {t("Preview")}
                          </p>
                        </div>
                      )}
                    </GridItem>

                    {/* <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Country"
                    id="country"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Postal Code"
                    id="postal-code"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem> */}
                  </GridContainer>
                  {/* <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <InputLabel style={{ color: "#AAAAAA" }}>
                      About me
                    </InputLabel>
                    <CustomInput
                      labelText="Lamborghini Mercy, Your chick she so thirsty, I'm in that two seat Lambo."
                      id="about-me"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5,
                      }}
                    />
                  </GridItem>
                </GridContainer> */}
                </CardBody>
                <CardFooter>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={edit_logged_user_request}
                  >
                    {t("Update_Profile")}
                    {edit_logged_user_request && <CircularProgress size={14} />}
                  </Button>
                </CardFooter>
              </form>
              <hr className={classes.hr} />
              {/* 2nd form */}
              <form onSubmit={formik2.handleSubmit}>
                <CardHeader>
                  <h4 className={classes.cardTitleBlack}>
                    {t("Update_Password")}
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer className={classes.topGridContainer}>
                    <GridItem xs={12} sm={12} md={4}>
                      <TextField
                        // sx={{ minWidth: "100%" }}
                        className={classes.passwordField}
                        id="outlined-required"
                        label={t("Old_Password")}
                        name="old_password"
                        onChange={formik2.handleChange}
                        value={formik2.values.old_password}
                        variant="standard"
                        error={
                          formik2.touched.old_password &&
                          Boolean(formik2.errors.old_password)
                        }
                        helperText={
                          formik2.touched.old_password &&
                          formik2.errors.old_password
                        }
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <TextField
                        id="outlined-required"
                        className={classes.passwordField}
                        label={t("New_Password")}
                        name="new_password"
                        onChange={formik2.handleChange}
                        value={formik2.values.new_password}
                        type="password"
                        variant="standard"
                        error={
                          formik2.touched.description &&
                          Boolean(formik2.errors.new_password)
                        }
                        helperText={
                          formik2.touched.new_password &&
                          formik2.errors.new_password
                        }
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <TextField
                        id="outlined-required"
                        label={t("Confirm_New_Password")}
                        name="confirm_new_password"
                        className={classes.passwordField}
                        onChange={formik2.handleChange}
                        value={formik2.values.confirm_new_password}
                        type="password"
                        variant="standard"
                        error={
                          formik2.touched.confirm_new_password &&
                          Boolean(formik2.errors.confirm_new_password)
                        }
                        helperText={
                          formik2.touched.confirm_new_password &&
                          formik2.errors.confirm_new_password
                        }
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button
                    // className={classes.btn}
                    type="submit"
                    color="primary"
                    disabled={edit_logged_user_request}
                  >
                    {t("Update_Password")}
                    {edit_logged_user_request && <CircularProgress size={14} />}
                  </Button>
                </CardFooter>
              </form>
              {/* end 2nd form */}
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card profile>
              <CardAvatar profile>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  {user.image_url ? (
                    <img src={URLS.BASE_URL_MEDIA + user.image_url} alt="..." />
                  ) : (
                    <img src={unknownPerson} alt="..." />
                  )}

                  {/* {user?.image && (
                    <img
                      src={URLS.BASE_URL_MEDIA + "" + user.image_url}
                      alt="..."
                    />
                  )} */}
                </a>
              </CardAvatar>

              <p>
                {
                  (data,
                  update_account_password_request,
                  update_account_password_success)
                }{" "}
              </p>
              <CardBody profile>
                <h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6>
                <h4 className={classes.cardTitle}>
                  {user.first_name + " " + user.last_name}
                </h4>

                <Button color="primary" round>
                  Follow
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </div>
  );
}
