// core components
// import GridItem from "components/Grid/GridItem.js";
import React from "react";
import { Button, Container, Grid, makeStyles } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
// Formik form
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody";
import { useDispatch, useSelector } from "react-redux";
import { createProduct } from "store/effects/productsEffect";
import { useTranslation } from "react-i18next";

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
const validationSchema = (t) =>
  yup.object({
    name: yup
      .string("Enter the product's name")
      .required("Product's name is required"),
    reference: yup
      .string("Enter the Product's reference")
      .required(t("The_Products_reference_is_required")),
  });

export default function NewProduct(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { create_product_request } = useSelector((state) => state.products);
  const formik = useFormik({
    initialValues: {
      email: "",
      reference: "",
    },
    validationSchema: validationSchema(t),
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
      dispatch(createProduct(values, props.history));
    },
  });
  return (
    <GridContainer>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{t("New_Product")}</h4>
          <p className={classes.cardCategoryWhite}>
            {t("You_are_creating_a_new_product")}
          </p>
        </CardHeader>
        <CardBody>
          <Container>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label={t("Products_Name")}
                    variant="outlined"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="reference"
                    name="reference"
                    label={t("Products_reference")}
                    variant="outlined"
                    value={formik.values.reference}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.reference &&
                      Boolean(formik.errors.reference)
                    }
                    helperText={
                      formik.touched.reference && formik.errors.reference
                    }
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    fullWidth
                    id="description"
                    name="description"
                    label={t("Products_description")}
                    variant="outlined"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    multiline
                    rows={5}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </Grid>
                <Grid item>
                  <Button
                    disabled={create_product_request}
                    type={"submit"}
                    variant="contained"
                    color="primary"
                  >
                    {t("Add_new_product")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
