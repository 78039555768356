// core components
// import GridItem from "components/Grid/GridItem.js";
import React, { useState } from "react";
import { Box, Button, Container, Grid, makeStyles } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
// Formik form
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody";
import { useDispatch, useSelector } from "react-redux";
// import { createProduct } from "store/effects/productsEffect";
import { useHistory } from "react-router-dom";
import { theme } from "components/Theme/Theme";
import { editProduct } from "store/effects/productsEffect";

const styles = {
  imContainer: {
    width: "50px",
    height: "50px",
  },
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  uploadImage: {
    "& .MuiButton-label": {
      color: theme.palette.primary.main,
    },
  },
};

const useStyles = makeStyles(styles);
const validationSchema = yup.object({
  name: yup
    .string("Enter the product's name")
    .required("Product's name is required"),
  reference: yup
    .string("Enter the Product's reference")
    .required("The Product's reference is required"),
});

export default function EditProductForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [image, setImage] = useState();
  const handleInputImage = (event) => {
    const files = event.target.files[0];
    setImage(files);
  };
  const { product } = props;
  const { edit_product_request } = useSelector((state) => state.products);
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      name: product.name,
      reference: product.reference,
      description: product.description,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
      let newDatas = new FormData();
      newDatas.append("name", values.name);
      newDatas.append("reference", values.reference);
      newDatas.append("description", values.description);
      if (image) {
        newDatas.append("image", image);
      }
      dispatch(editProduct(product.id, values, history));
    },
  });
  return (
    <GridContainer>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Edit product form</h4>
          <p className={classes.cardCategoryWhite}>You are editing a product</p>
        </CardHeader>
        <CardBody>
          <Container>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Product's Name"
                    variant="outlined"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="reference"
                    name="reference"
                    label="Product's reference"
                    variant="outlined"
                    value={formik.values.reference}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.reference &&
                      Boolean(formik.errors.reference)
                    }
                    helperText={
                      formik.touched.reference && formik.errors.reference
                    }
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    fullWidth
                    id="description"
                    name="description"
                    label="Product's description"
                    variant="outlined"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    multiline
                    rows={5}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </Grid>
                <Grid item md={12}>
                  <input
                    accept="image/*"
                    hidden
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleInputImage}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      className={classes.uploadImage}
                      variant="outlined"
                      color="primary"
                      component="span"
                    >
                      Upload an image
                    </Button>
                  </label>
                  {image && <p>{image?.name}</p>}
                  <Box className={classes.imContainer}>
                    {!image && product.image && (
                      <img width={"50px"} src={product.image} />
                    )}
                  </Box>
                </Grid>
                <Grid item>
                  <Button
                    disabled={edit_product_request}
                    type={"submit"}
                    variant="contained"
                    color="primary"
                  >
                    Edit product
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
