import { toast } from "react-toastify";
import { API } from "services/axios";
import { loadingSalerProfileRequest } from "store/actions/salerProfileAction";
import { editSalerProfileFailed } from "store/actions/salerProfileAction";
import { editSalerProfileSuccess } from "store/actions/salerProfileAction";
import { editSalerProfileRequest } from "store/actions/salerProfileAction";
import { loadingSalerProfilesSuccess } from "store/actions/salerProfileAction";
import { loadingSalerProfileFailed } from "store/actions/salerProfileAction";
import { URLS } from "../../services/urls/urls";

export function getSalerProfile(id) {
  return (dispatch) => {
    dispatch(loadingSalerProfileRequest());

    API.get(URLS.USERS.USER.EDIT(id))
      // API.get(`/users/user/${id}/`)
      .then((response) => {
        dispatch(loadingSalerProfilesSuccess(response.data));
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ?? "Loading saler error!! Please try later"
        );
        console.log(URLS.USERS.SALER.GET(id));
        dispatch(loadingSalerProfileFailed());
      });
  };
}

export function editSalerProfile(id, data, history) {
  return (dispatch) => {
    dispatch(editSalerProfileRequest());
    API.patch(URLS.USERS.USER.EDIT(id), data)

      .then((response) => {
        dispatch(editSalerProfileSuccess(id, response.data));
        history.push("/admin/salers");
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ?? "saler edit failed!! Please try later"
        );

        dispatch(editSalerProfileFailed());
      });
  };
}
