import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {
  Button,
  Box,
  LinearProgress,
  IconButton,
  TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadingProducts } from "store/effects/productsEffect";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PublishIcon from '@material-ui/icons/Publish';

// core components
import tableStyles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { withStyles } from "@material-ui/styles";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Tooltip from "@material-ui/core/Tooltip";
import EditProduct from "./components/editProduct/EditProduct";
import Swal from "sweetalert2";
import { deleteFormat } from "store/effects/productsEffect";
import { useTranslation } from "react-i18next";
import { API } from "services/axios";
import { toast } from "react-toastify";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  mlAuto: {
    marginLeft: "auto",
  },
  dFlex: {
    display: "flex",
  },
  table: {
    minWidth: 650,
  },
  textSearch: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
  },
  button: {
    background: "#7EA6BF",

    "&:hover,&:focus": {
      background: "#436e89",
    },
  },
};
const SearchTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: "white",
      borderWidth: 2,
    },
    background: '#fff',
    borderRadius: '4px',
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 0,
      padding: "4px !important", // override inline-style
    },
    "& fieldset": {
      borderColor: "#ffffff",
    },
    "& input": {
      color: "#000",
    },
  },
})(TextField);

const useStyles = makeStyles(styles);
const useStyles1 = makeStyles(tableStyles);

export default function ProductsList() {
  const classes = useStyles();
  const classes1 = useStyles1();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { productsList, loading_products_request } = useSelector(
    (state) => state.products
  );
  // const [isOpen, setIsOpen] = useState(false);

  const [file, setFile] = useState("");

  // const fields = [
  //   {
  //     // Visible in table header and when matching columns.
  //     label: "Name",
  //     // This is the key used for this field when we call onSubmit.
  //     key: "name",
  //     // Allows for better automatic column matching. Optional.
  //     alternateMatches: ["first name", "first"],
  //     // Used when editing and validating information.
  //     fieldType: {
  //       // There are 3 types - "input" / "checkbox" / "select".
  //       type: "input",
  //     },
  //     // Used in the first step to provide an example of what data is expected in this field. Optional.
  //     example: "Stephanie",
  //     // Can have multiple validations that are visible in Validation Step table.
  //     validations: [
  //       {
  //         // Can be "required" / "unique" / "regex"
  //         rule: "required",
  //         errorMessage: "Name is required",
  //         // There can be "info" / "warning" / "error" levels. Optional. Default "error".
  //         level: "error",
  //       },
  //     ],
  //   },
  // ] ;

  // // const logData = useSelector((state) => state.auth);
  // const onSubmit = () => {};
  // const onClose = () => {
  //   setIsOpen(true);
  // };
  const handleSearch = (event) => {
    dispatch(loadingProducts(event.target.value ?? ""));
  };

  const handleSubmitFile = (e) => {
    let formData = new FormData();
    let excel = e.target.files[0];
    formData.append("price_list_file", excel);

    API.post("https://api.cadyst.ova-consulting.fr/update-products/", formData)
      .then((response) => {
        const { data } = response;
        setFile(data);
        // setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          error.response.data.detail ??
            error.response.data.error ??
            " file upload error!! Please try later"
        );
      });
  };

  const handleDeleteProduct = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text:
        "You want to delete a product fromat. You won't be able to revert this! ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteFormat(id));
      }
    });
  };
  useEffect(() => {
    dispatch(loadingProducts(""));
  }, []);

  return (
    <GridContainer>
      {/* <ReactSpreadsheetImport
        isOpen={true}
        onClose={null}
        onSubmit={null}
        fields={fields}
      /> */}
      <GridItem xs={12} sm={12} md={12}>
        <Box sx={
          {
            display: 'flex'
          }
        } >
          <Box>
            <input
            accept=".CSV,.csv,.XLSX,.xlsx,.XLS"
            hidden
            name="price_list_file"
            id="contained-button-file"
            type="file"
            onChange={handleSubmitFile}
            // value={formik.values.image}
          />
          <label htmlFor="contained-button-file">
            <Button
              startIcon={<PublishIcon />}
              color={"primary"}
              component="span"
              variant={"contained"}
              >
              Upload excel
            </Button>
          </label>

          <p>{file} </p>
          </Box>
          <Box sx={{marginLeft: 'auto'}}>
            <Button
              variant={"contained"}
              color={"primary"}
              startIcon={<AddIcon />}
              component={Link}
              to={"/admin/products/formats/new"}
              style={{ marginRight: "1rem" }}
            >
              {t("New_Format")}
            </Button>
            <Button
              variant={"contained"}
              color={"primary"}
              startIcon={<AddIcon />}
              component={Link}
              to={"/admin/products/new"}
            >
              {t("New_Product")}
            </Button>
          </Box>
        </Box>
        
        <Card>
          <CardHeader color="primary">
            <Box className={classes.dFlex}>
              <div>
                <h4 className={classes.cardTitleWhite}>{t("Products_list")}</h4>
                {console.log("product List", productsList)}
                <p className={classes.cardCategoryWhite}>
                  {t("The_list_of_products_according_to_your_branch")}
                </p>
              </div>
              <div className={classes.mlAuto}>
                <SearchTextField
                  id="outlined-basic"
                  placeholder={t("Search")}
                  variant="outlined"
                  onChange={handleSearch}
                  style={{ marginRight: "1rem", marginTop: "0px" }}
                  margin="dense"
                />
                
              </div>
            </Box>
          </CardHeader>
          <CardBody>
            <TableContainer>
              <Table className={classes1.table} aria-label="simple table">
                <TableHead className={classes1["primaryTableHeader"]}>
                  <TableRow className={classes1.tableHeadRow}>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      {t("Product")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      {t("Format")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      {t("Reference")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      {t("Currency")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      {t("Default_Price")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productsList.results.map((product) => {
                    const { formats } = product;
                    return formats?.map((format) => {
                      return (
                        <TableRow
                          key={format.id}
                          className={classes1.tableBodyRow}
                        >
                          {console.log("product", product)}
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes1.tableCell}
                          >
                            {product.name}
                          </TableCell>
                          <TableCell className={classes1.tableCell}>
                            {format.label}
                          </TableCell>
                          <TableCell className={classes1.tableCell}>
                            {format.reference}
                          </TableCell>
                          <TableCell className={classes1.tableCell}>
                            {format.currency ?? "XAF"}
                          </TableCell>
                          <TableCell className={classes1.tableCell}>
                            {format.price}
                          </TableCell>
                          <TableCell
                            className={classes1.tableCell}
                            style={{ display: "flex" }}
                          >
                            <Tooltip title="Edit" arrow>
                              <EditProduct product={product} format={format} />
                            </Tooltip>{" "}
                            <Tooltip title="Delete" arrow>
                              <IconButton
                                onClick={() => handleDeleteProduct(format.id)}
                              >
                                <DeleteOutlineIcon color="error" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    });
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {loading_products_request && <LinearProgress />}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
