import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {
  Button,
  Box,
  LinearProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddIcon from "@material-ui/icons/Add";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadingProducts } from "store/effects/productsEffect";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// core components
import tableStyles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { withStyles } from "@material-ui/styles";
import { loadingPricesLists } from "store/effects/priceListsEffect";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { useTranslation } from "react-i18next";
import { API } from "services/axios";
import { toast } from "react-toastify";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  mlAuto: {
    marginLeft: "auto",
  },
  topBtn: {},
  marginRight: {
    marginRight: "10px",
  },

  dFlex: {
    display: "flex",
  },
  table: {
    minWidth: 650,
  },
  textSearch: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
  },
};
const SearchTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: "white",
      borderWidth: 2,
    },
    background: '#fff',
    borderRadius: '4px',
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
    "& fieldset": {
      borderColor: "#ffffff",
    },
    "& input": {
      color: "#ffffff",
    },
  },
})(TextField);

const useStyles = makeStyles(styles);
const useStyles1 = makeStyles(tableStyles);

export default function PricesLists() {
  const classes = useStyles();
  const classes1 = useStyles1();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pricesListList, loading_price_lists_request } = useSelector(
    (state) => state.pricesList
  );
  const [file, setFile] = useState("");

  // const handleFile = (e) => {
  //   let received = e.target.value;
  //   setFile(received);
  //   // API.post()
  // };
  const handleSubmitFile = (e) => {
    let formData = new FormData();
    let excel = e.target.files[0];
    formData.append("price_list_file", excel);

    API.post(
      "https://api.cadyst.ova-consulting.fr/update-pricelists/",
      formData
    )
      .then((response) => {
        const { data } = response;
        setFile(data);
        // setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          error.response.data.detail ??
            error.response.data.error ??
            " file upload error!! Please try later"
        );
      });
  };

  const handleSearch = (event) => {
    dispatch(loadingProducts(event.target.value ?? ""));
  };
  useEffect(() => {
    dispatch(loadingPricesLists(""));
  }, []);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Box sx={{display: 'flex'}}>
          <Box sx={{marginLeft: ''}} mb={2}>
            
          <Button
                    variant={"contained"}
                    color={"primary"}
                    startIcon={<AddIcon />}
                    component={Link}
                    className={classes.marginRight}
                    to={"/admin/payments/prices-list/new"}
                  >
                    {t("New_price_list")}
                  </Button>
                  <input
                    // accept="image/*"
                    accept=".csv,.XLSX,.XLS"
                    hidden
                    name="file"
                    id="contained-button-file"
                    type="file"
                    onChange={handleSubmitFile}
                    // value={formik.values.image}
                  />

                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      {t("Upload_file")}
                    </Button>
                  </label>
          </Box>
        </Box>
        <Card>
          <CardHeader color="primary">
            <Box className={classes.dFlex}>
              <div>
                <h4 className={classes.cardTitleWhite}>{t("Prices_list")}</h4>
                <p className={classes.cardCategoryWhite}>
                  {t("Customers_prices_list_according_to_your_branch")}
                  {file}
                </p>
              </div>
              <div className={classes.mlAuto}>
                <div className={classes.topBtn}>
                  <SearchTextField
                    id="outlined-basic"
                    label={t("Search")}
                    variant="outlined"
                    onChange={handleSearch}
                    style={{ marginRight: "1rem", marginTop: "0px" }}
                    margin="dense"
                  />

                </div>
              </div>
            </Box>
          </CardHeader>
          <CardBody>
            <TableContainer>
              <Table className={classes1.table} aria-label="simple table">
                <TableHead className={classes1["primaryTableHeader"]}>
                  <TableRow className={classes1.tableHeadRow}>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      Client
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      {t("Product")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      {t("Currency")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      {t("Price")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      {t("Created_at")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      {t("Updated_at")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      {t("Status")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      Description
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pricesListList.results.map((price) => {
                    return (
                      <TableRow
                        key={price.id}
                        className={classes1.tableBodyRow}
                      >
                        <TableCell className={classes1.tableCell}>
                          {price.client.first_name +
                            " " +
                            price.client.last_name}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes1.tableCell}
                        >
                          {price.format.product.name +
                            " - " +
                            price.format.label}
                        </TableCell>
                        <TableCell className={classes1.tableCell}>
                          {price.currency ?? "XAF"}
                        </TableCell>
                        <TableCell className={classes1.tableCell}>
                          {price.price}
                        </TableCell>
                        <TableCell className={classes1.tableCell}>
                          {price.created_at}
                        </TableCell>
                        <TableCell className={classes1.tableCell}>
                          {price.updated_at}
                        </TableCell>
                        <TableCell className={classes1.tableCell}>
                          {price.is_active ? (
                            <span style={{ color: "green" }}>Active</span>
                          ) : (
                            <span style={{ color: "red" }}>Disabled</span>
                          )}
                        </TableCell>
                        <TableCell className={classes1.tableCell}>
                          {price.description}
                        </TableCell>
                        <TableCell className={classes1.tableCell}>
                          {!price.is_active ? (
                            <Tooltip title="Activate" arrow>
                              <IconButton>
                                <DoneAllIcon color="success" />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Disable" arrow>
                              <IconButton>
                                <NotInterestedIcon color="error" />
                              </IconButton>
                            </Tooltip>
                          )}{" "}
                          <Tooltip title="Edit" arrow>
                            <IconButton>
                              <EditOutlinedIcon color="error" />
                            </IconButton>
                          </Tooltip>{" "}
                          <Tooltip title="Delete" arrow>
                            <IconButton>
                              <DeleteOutlineIcon color="error" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {loading_price_lists_request && <LinearProgress />}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
