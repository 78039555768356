// Visits  actions types

export const EDIT_VISITS_REQUEST = "EDIT_VISITS_REQUEST";
export const EDIT_VISITS_SUCCESS = "EDIT_VISITS_SUCCESS";
export const EDIT_VISITS_FAILED = "EDIT_VISITS_FAILED";

export const DELETE_VISITS_REQUEST = "DELETE_VISITS_REQUEST";
export const DELETE_VISITS_SUCCESS = "DELETE_VISITS_SUCCESS";
export const DELETE_VISITS_FAILED = "DELETE_VISITS_FAILED";

export const LOAD_VISITS_REQUEST = "LOAD_VISITS_REQUEST";
export const LOAD_VISITS_SUCCESS = "LOAD_VISITS_SUCCESS";
export const LOAD_VISITS_FAILED = "LOAD_VISITS_FAILED";

// Edit visits actions

export function editVisitsRequest() {
  return {
    type: EDIT_VISITS_REQUEST,
  };
}

export function editVisitsSuccess(id, data) {
  return {
    type: EDIT_VISITS_SUCCESS,
    payload: { id, data },
  };
}

export function editVisitsFailed() {
  return {
    type: EDIT_VISITS_FAILED,
  };
}

// Delete Visits

export function deleteVisitsRequest() {
  return {
    type: DELETE_VISITS_REQUEST,
  };
}

export function deleteVisitsSuccess(id) {
  return {
    type: DELETE_VISITS_SUCCESS,
    payload: { id: id },
  };
}

export function deleteVisitsFailed() {
  return {
    type: DELETE_VISITS_FAILED,
  };
}
// Load Visits actions

export function loadVisitsRequest() {
  return {
    type: LOAD_VISITS_REQUEST,
  };
}

export function loadVisitsSuccess(visits) {
  return {
    type: LOAD_VISITS_SUCCESS,
    payload: visits,
  };
}

export function loadVisitsFailed() {
  return {
    type: LOAD_VISITS_FAILED,
  };
}
