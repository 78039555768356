export default {
  translation: {
    "shared.french": "French",
    "shared.english": "English",
    "shared.internet_connexion_error": "Vérifiez votre connexion Internet!",
    visits_list: "Visits list",
    The_list_of_visits_according_to_your_branch:
      "The list of visits according to your branch",
    Is_Completed: "Is Completed",
    Edit_Profile: "Edit Profile",
    Complete_your_profile: "Complete your profile",
    Phone_Number: "Phone Number",
    First_Name: "First name",
    Last_Name: "Last name",
    Upload_an_image: "Upload an image",
    Update_Profile: "Update Profile",
    Image_should_be_lesser_or_equal_to_200_mb:
      "Image should be lesser or equal to 200 mb",
    enter_number_lesser_than_10_digits: "enter number lesser than 10 digits",
    Invalid_email: "Invalid email",
    file_too_large: "file too large",
    Created_By: "Created by",
    Joined_At: "Joined at",
    Full_Name: "Full name",
    The_list_of_salers_according_to_your_branch:
      "The list of salers according to your branch",
    Salers_List: "Salers list",
    New_Saler: "New Saler",
    Create_Saler: "Create saler",
    Previous_Quantity: "Previous quantity",
    Current_Quantity: "Current quantity",
    Visits_List: "Visits list",
    The_list_of_salers_Visits_according_to_your_branch:
      "The list of salers Visits according to your branch",
    You_are_creating_a_new_saler_profile:
      "You are creating a new saler profile",
    Select_An_Address: "Select an address *",
    Visit_Date: "Visit date",
    Visit_Object: "Visit object",
    Managed_By: "Managed by",
    Pnz_client_status: "Pnz client status",
    New_Client: "NEW CLIENT",
    New_Address: "NEW ADDRESS",
    The_list_of_clients_according_to_your_branch:
      "The list of clients according to your branch",
    delete_message_warning:
      "You want to delete a payment method. You won't be able to revert this!",
    Are_you_sure: "Are you sure?",
    Delete_validation: "Yes, delete it!",
    Clients_List: "Clients list",
    New_Address_caps: "New Address",
    Town: "Town",
    Address_Type: "Address type *",
    Select_a_Client: "Select a Client *",
    Address_Image: "ADDRESS IMAGE",
    Create_address: "Create address",
    Select_a_Saler: "Select a Saler *",
    Client_contract: "Client contract",
    Add_new_client: "Add new client",
    New_client: "New client",
    You_are_creating_a_client: "You are creating a client",
    Enter_the_clients_first_name: "Enter the client's first name",
    First_name_is_required: "First name is required",
    Enter_the_clients_last_name: "Enter the client's last name",
    Last_name_is_required: "Last name is required",
    Enter_the_clients_reference: "Enter the client's reference",
    The_clients_reference_is_required: "The client's reference is required",
    Enter_clients_email: "Enter client's email",
    The_clients_email_is_required: "The client's email is required",
    Enter_clients_phone_number: "Enter client's phone number",
    The_clients_phone_number_is_required:
      "The client's phone number is required",
    Enter_clients_phone_number_2: "Enter client's phone number 2",
    Enter_clients_region: "Enter client's region",
    The_clients_region_is_required: "The client's region is required",
    Enter_panzani_clients_status: "Enter panzani clients status",
    The_panzani_clients_status_is_required:
      "The panzani clients status is required",
    Enter_clients_status: "Enter client's status",
    The_clients_status_is_required: "The client's status is required",
    Enter_clients_plafond: "Enter client's limit",
    The_clients_plafond_is_required: "The client's limit is required",
    Payments_list: "Payments list",
    The_list_of_payments_according_to_your_branch:
      "The list of payments according to your branch",
    New_Payment_Method: "New Payment Method",
    Payment_name: "Payment name",
    You_are_creating_a_new_payment_method:
      "You are creating a new payment method",
    Payment_method_name: "Payment method name",
    payments_method_name_is_required: "payment's method name is required",
    create_method: "create method",
    Prices_list: "Prices list",
    Customers_prices_list_according_to_your_branch:
      "Customers prices list according to your branch",
    New_price_list: "New price list",
    Created_at: "Created at",
    Updated_at: "Updated at",
    The_prices_description_is_required: "The price's description is required",
    The_Formats_price_is_required: "The Format's price is required",
    You_are_creating_a_price_list: "You are creating a price list",
    Select_a_client: "Select a client *",
    Select_a_format: "Select a format *",
    save_the_price: "save the price",
    The_list_of_products_according_to_your_branch:
      "The list of products according to your branch",
    Products_list: "Products list",
    New_Format: "New Format",
    New_Product: "New Product",
    Default_Price: "Default Price",
    You_are_creating_a_new_product: "You are creating a new product",
    Products_Name: "Product's Name",
    Products_reference: "Product's reference *",
    Add_new_product: "Add new product",
    Products_description: "Product's description",
    The_Products_reference_is_required: "The Product's reference is required",
    Formats_name_is_required: "Format's name is required",
    The_Formats_reference_is_required: "The Format's reference is required",
    Formats_label: "Format's label *",
    Formats_reference: "Format's reference *",
    Formats_default_price: "Format's default price",
    Select_a_product: "Select a product *",
    Orders_list: "Orders list",
    The_list_of_orders_according_to_your_branch:
      "The list of orders according to your branch",
    Price_before_tax: "Price before tax",
    Price_after_tax: "Price_after_tax",
    Payment_method: "Payment method",
    Edited_at: "Edited at",
    Unit_price: "Unit price",
    Total_price: "Total price",
    Salers_list: "Salers list",
    Clients_Addresses: "Clients Addresses",
    Clients_list: "Clients list",
    New_Prices_List: "New Prices List",
    New_payment_method: "New payment method",
    Payment_methods: "Payment methods",
    Confirm_Password: "Confirm Password",
    Old_Password: "Old Password",
    New_Password: "New Password",
    Update_Password: "Update Password",
    Confirm_New_Password: "Confirm new password",
    Password_should_be_at_least_8_characters:
      "Password should be at least 8 characters",
    password_must_contain_at_least_1_lower_case_letter:
      "password must contain at least 1 lower case letter",
    password_must_contain_at_least_1_upper_case_letter:
      "password must contain at least 1 upper case letter",
    password_must_contain_at_least_1_number:
      "password must contain at least 1 number",
    password_must_contain_at_least_1_special_character:
      "password must contain at least 1 special character",
    New_Password_is_required: "New Password is required",
    Please_confirm_password: "Please confirm password",
    Passwords_must_match: "Passwords must match",
    Password_is_required: "Password is required",
  },
};
