export const LOADING_PRODUCTS_REQUEST = "LOADING_PRODUCTS_REQUEST";
export const LOADING_PRODUCTS_SUCCESS = "LOADING_PRODUCTS_SUCCESS";
export const LOADING_PRODUCTS_FAILED = "LOADING_PRODUCTS_FAILED";

export const CREATE_PRODUCT_REQUEST = "CREATE_PRODUCT_REQUEST";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILED = "CREATE_PRODUCT_FAILED";

export const EDIT_PRODUCT_REQUEST = "EDIT_PRODUCT_REQUEST";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_FAILED = "EDIT_PRODUCT_FAILED";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILED = "DELETE_PRODUCT_FAILED";

export const LOAD_PRODUCT_REQUEST = "LOAD_PRODUCT_REQUEST";
export const LOAD_PRODUCT_SUCCESS = "LOAD_PRODUCT_SUCCESS";
export const LOAD_PRODUCT_FAILED = "LOAD_PRODUCT_FAILED";
// Products formats actions types
export const LOADING_FORMATS_REQUEST = "LOADING_FORMATS_REQUEST";
export const LOADING_FORMATS_SUCCESS = "LOADING_FORMATS_SUCCESS";
export const LOADING_FORMATS_FAILED = "LOADING_FORMATS_FAILED";

export const CREATE_FORMAT_REQUEST = "CREATE_FORMAT_REQUEST";
export const CREATE_FORMAT_SUCCESS = "CREATE_FORMAT_SUCCESS";
export const CREATE_FORMAT_FAILED = "CREATE_FORMAT_FAILED";

export const EDIT_FORMAT_REQUEST = "EDIT_FORMAT_REQUEST";
export const EDIT_FORMAT_SUCCESS = "EDIT_FORMAT_SUCCESS";
export const EDIT_FORMAT_FAILED = "EDIT_FORMAT_FAILED";

export const DELETE_FORMAT_REQUEST = "DELETE_FORMAT_REQUEST";
export const DELETE_FORMAT_SUCCESS = "DELETE_FORMAT_SUCCESS";
export const DELETE_FORMAT_FAILED = "DELETE_FORMAT_FAILED";

export const LOAD_FORMAT_REQUEST = "LOAD_FORMAT_REQUEST";
export const LOAD_FORMAT_SUCCESS = "LOAD_FORMAT_SUCCESS";
export const LOAD_FORMAT_FAILED = "LOAD_FORMAT_FAILED";

export function loadingProductsRequest() {
  return {
    type: LOADING_PRODUCTS_REQUEST,
  };
}

export function loadingProductsSuccess(data, isAdmin) {
  return {
    type: LOADING_PRODUCTS_SUCCESS,
    payload: { data, isAdmin },
  };
}

export function loadingProductsFailed() {
  return {
    type: LOADING_PRODUCTS_FAILED,
  };
}

// Create product action

export function createProductRequest() {
  return {
    type: CREATE_PRODUCT_REQUEST,
  };
}

export function createProductSuccess(data) {
  return {
    type: CREATE_PRODUCT_SUCCESS,
    payload: data,
  };
}

export function createProductFailed() {
  return {
    type: CREATE_PRODUCT_FAILED,
  };
}

// Edit product actions

export function editProductRequest() {
  return {
    type: EDIT_PRODUCT_REQUEST,
  };
}

export function editProductSuccess(id, data) {
  return {
    type: EDIT_PRODUCT_SUCCESS,
    payload: { id, data },
  };
}

export function editProductFailed() {
  return {
    type: EDIT_PRODUCT_FAILED,
  };
}

// Delete Product actions

export function deleteProductRequest() {
  return {
    type: DELETE_PRODUCT_REQUEST,
  };
}

export function deleteProductSuccess(id) {
  return {
    type: DELETE_PRODUCT_SUCCESS,
    payload: { id: id },
  };
}

export function deleteProductFailed() {
  return {
    type: DELETE_PRODUCT_FAILED,
  };
}
// Load Product actions

export function loadProductRequest() {
  return {
    type: LOAD_PRODUCT_REQUEST,
  };
}

export function loadProductSuccess(product) {
  return {
    type: LOAD_PRODUCT_SUCCESS,
    payload: product,
  };
}

export function loadProductFailed() {
  return {
    type: LOAD_PRODUCT_FAILED,
  };
}

/* Formats actions 

/ Load formats*/
export function loadingFormatsRequest() {
  return {
    type: LOADING_FORMATS_REQUEST,
  };
}

export function loadingFormatsSuccess(data, isAdmin) {
  return {
    type: LOADING_FORMATS_SUCCESS,
    payload: { data, isAdmin },
  };
}

export function loadingFormatsFailed() {
  return {
    type: LOADING_FORMATS_FAILED,
  };
}

// Create format action

export function createFormatRequest() {
  return {
    type: CREATE_FORMAT_REQUEST,
  };
}

export function createFormatSuccess(data) {
  return {
    type: CREATE_FORMAT_SUCCESS,
    payload: data,
  };
}

export function createFormatFailed() {
  return {
    type: CREATE_FORMAT_FAILED,
  };
}

// Edit format

export function editFormatRequest() {
  return {
    type: EDIT_FORMAT_REQUEST,
  };
}

export function editFormatSuccess(id, data) {
  return {
    type: EDIT_FORMAT_SUCCESS,
    payload: { id, data },
  };
}

export function editFormatFailed() {
  return {
    type: EDIT_FORMAT_FAILED,
  };
}

// Delete format

export function deleteFormatRequest() {
  return {
    type: DELETE_FORMAT_REQUEST,
  };
}

export function deleteFormatSuccess(id) {
  return {
    type: DELETE_FORMAT_SUCCESS,
    payload: { id },
  };
}

export function deleteFormatFailed() {
  return {
    type: DELETE_FORMAT_FAILED,
  };
}
