import SecureLS from "secure-ls";
import { CHANGE_LANGUAGE } from "store/actions/InternationalizationActions";
import { SET_TRANSLATOR_FUNCTION } from "store/actions/InternationalizationActions";
// import { CHANGE_LANGUAGE, SET_TRANSLATOR_FUNCTION } from "store/types";

const DEFAULT_LANGUAGE = "fr";

const ls = new SecureLS({ encodingType: "aes" });

export const initialState = {
  locale: ls.get("lang") || DEFAULT_LANGUAGE,
};

export const InternationalisationReducers = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        locale: action.language,
      };
    case SET_TRANSLATOR_FUNCTION:
      return {
        ...state,
        t: action.t,
      };
    default:
      return state;
  }
};
