export const UPDATE_ACCOUNT_PASSWORD_SUCCESS =
  "UPDATE_ACCOUNT_PASSWORD_SUCCESS";
export const UPDATE_ACCOUNT_PASSWORD_REQUEST =
  "UPDATE_ACCOUNT_PASSWORD_REQUEST";
export const UPDATE_ACCOUNT_PASSWORD_FAILED = "UPDATE_ACCOUNT_PASSWORD_FAILED";

// edit saler's profile
export function updateAccountPasswordRequest() {
  return {
    type: UPDATE_ACCOUNT_PASSWORD_REQUEST,
  };
}

export function updateAccountPasswordSuccess(data) {
  return {
    type: UPDATE_ACCOUNT_PASSWORD_SUCCESS,
    payload: data,
  };
}

export function updateAccountPasswordFailed() {
  return {
    type: UPDATE_ACCOUNT_PASSWORD_FAILED,
    // payload: data,
  };
}
