import { toast } from "react-toastify";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls/urls";
import {
  loadingPaymentsFailed,
  loadingPaymentsRequest,
  loadingPaymentsSuccess,
  createPaymentFailed,
  createPaymentRequest,
  createPaymentSuccess,
  deletePaymentFailed,
  deletePaymentRequest,
  deletePaymentSuccess,
  editPaymentFailed,
  editPaymentRequest,
  editPaymentSuccess,
  loadPaymentFailed,
  loadPaymentRequest,
  loadPaymentSuccess,
} from "../actions/PaymentsActions";

export function loadingPaymentMethods() {
  return (dispatch) => {
    dispatch(loadingPaymentsRequest());
    API.get(URLS.PAYMENTS_METHODS.LIST)
      .then((response) => {
        dispatch(loadingPaymentsSuccess(response.data));
        // history.push('/dashboard');
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ??
            "Error while loading !! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(loadingPaymentsFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function createPayment(data, history) {
  return (dispatch) => {
    dispatch(createPaymentRequest());
    API.post(URLS.PAYMENTS_METHODS.CREATE, data)
      .then((response) => {
        toast.success("New payment method added.");
        const { data } = response;
        dispatch(createPaymentSuccess(data));
        history.push("/admin/payments/methods");
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ??
            " Payment creation error!! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(createPaymentFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function editPayment(id, data, history) {
  return (dispatch) => {
    dispatch(editPaymentRequest());
    API.put(URLS.PAYMENTS_METHODS.EDIT(id), data)
      .then((response) => {
        dispatch(editPaymentSuccess(id, response.data));
        history.push("/admin/trades");
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ?? "Payment edit failed!! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(editPaymentFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

// Close position

export function closePayment(id, data, history, resetForm, handleCloseModal) {
  return (dispatch) => {
    const idToast = toast.loading("Closing trade ...");
    API.put(URLS.PAYMENTS_METHODS.CLOSE(id), data)
      .then(() => {
        toast.update(idToast, {
          render: "Payment closed successfully",
          type: "success",
          isLoading: false,
        });
        dispatch(deletePaymentSuccess(id));
        resetForm();
        handleCloseModal();
        history.push("/admin/trades");
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.update(idToast, {
          render:
            error.response.data.detail ??
            "Payment closing failed !! Please try later",
          type: "error",
          isLoading: false,
          autoClose: true,
        });
        // const errorMessage = formatError(error.response.data);
        dispatch(deletePaymentFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

// Delete wallet effect
export function deletePayment(id) {
  return (dispatch) => {
    dispatch(deletePaymentRequest());
    const idToast = toast.loading("Please wait...");
    API.delete(URLS.PAYMENTS_METHODS.DELETE(id))
      .then(() => {
        toast.update(idToast, {
          render: "Payment deleted successfully",
          type: "success",
          isLoading: false,
        });
        dispatch(deletePaymentSuccess(id));
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.update(idToast, {
          render:
            error.response.data.detail ??
            "Payment deletion failed !! Please try later",
          type: "error",
          isLoading: false,
          autoClose: true,
        });
        // const errorMessage = formatError(error.response.data);
        dispatch(deletePaymentFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

// Load wallet effect
export function loadPayment(id) {
  return (dispatch) => {
    dispatch(loadPaymentRequest());
    API.get(URLS.PAYMENTS_METHODS.GET(id))
      .then((response) => {
        dispatch(loadPaymentSuccess(response.data));
        // history.push('/dashboard');
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ??
            "Loading wallet error!! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(loadPaymentFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}
