import { toast } from "react-toastify";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls/urls";
import {
  createClientFailed,
  createClientRequest,
  createClientSuccess,
  deleteClientFailed,
  deleteClientRequest,
  deleteClientSuccess,
  editClientFailed,
  editClientRequest,
  editClientSuccess,
  loadingClientsFailed,
  loadingClientsRequest,
  loadingClientsSuccess,
  loadClientFailed,
  loadClientRequest,
  loadClientSuccess,
  createAddressFailed,
  createAddressRequest,
} from "../actions/clientsActions";

export function loadingClients(search, page) {
  return (dispatch) => {
    dispatch(loadingClientsRequest());
    API.get(URLS.USERS.CLIENTS.LIST(search ?? "", page))
      .then((response) => {
        dispatch(loadingClientsSuccess(response.data));
        // history.push('/dashboard');
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          error.response.data.detail ?? "Login error !! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(loadingClientsFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function createClient(data, history) {
  return (dispatch) => {
    dispatch(createClientRequest());
    API.post(URLS.USERS.CLIENTS.CREATE, data)
      .then((response) => {
        const { data } = response;
        dispatch(createClientSuccess(data));
        history.push("/admin/clients");
        toast.success(
            "Le client a bien été créé"
        );
      })
      .catch((error) => {
        toast.error(
          error.response.data.detail ??
          // error.response.data ??
            " Client creation error!! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(createClientFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function editClient(id, data, history) {
  return (dispatch) => {
    dispatch(editClientRequest());
    API.put(URLS.WALLETS.EDIT(id), data)
      .then((response) => {
        dispatch(editClientSuccess(id, response.data));
        history.push("/admin/wallets");
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ?? "Client edit failed!! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(editClientFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

// Delete wallet effect
export function deleteClient(id, history) {
  return (dispatch) => {
    dispatch(deleteClientRequest());
    const idToast = toast.loading("Please wait...");
    API.delete(URLS.WALLETS.DELETE(id))
      .then(() => {
        toast.update(idToast, {
          render: "Client deleted successfully",
          type: "success",
          isLoading: false,
        });
        dispatch(deleteClientSuccess(id));
        history.push("/admin/wallets");
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.update(idToast, {
          render:
            error.response.data.detail ??
            "Client deletion failed !! Please try later",
          type: "error",
          isLoading: false,
          autoClose: true,
        });
        // const errorMessage = formatError(error.response.data);
        dispatch(deleteClientFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

// Load wallet effect
export function loadClient(id) {
  return (dispatch) => {
    dispatch(loadClientRequest());
    API.get(URLS.WALLETS.GET(id))
      .then((response) => {
        dispatch(loadClientSuccess(response.data));
        // history.push('/dashboard');
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ??
            "Loading wallet error!! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(loadClientFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

// Addresses effects

export function createAddress(data, onSuccess) {
  return (dispatch) => {
    dispatch(createAddressRequest());
    API.post(URLS.USERS.CLIENTS.ADDRESSES.CREATE, data)
      .then((response) => {
        const { data } = response;
        console.log(data);
        // dispatch(createClientSuccess(data));
        // history.push("/admin/clients");
        toast.success("Address successfuly added");
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ??
            " Client creation error!! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(createAddressFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}
