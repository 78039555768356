const CLIENT_TYPE = [
  {
    value: "PROSPECT",
    label: "Prospect",
  },
  {
    value: "CLIENT",
    label: "Client",
  },
];

const CLIENT_REGIONS = [
  {
    value: "LSO",
    label: "Littoral sud ouest (LSO)",
  },
  {
    value: "NSO",
    label: "Nord sud ouest (NSO)",
  },
  {
    value: "CS",
    label: "CS",
  },
  {
    value: "ONO",
    label: "ONO",
  },
  {
    value: "GNO 1",
    label: "GNO 1",
  },
  {
    value: "GNO 2",
    label: "GNO 2",
  },
];

const PNZ_CLIENT_STATUS = [
  {
    value: "GMS",
    label: "Grande et moyenne surface (GMS)",
  },
  {
    value: "NPS",
    label: "Nouveaux partenaires strategiques (NPS)",
  },
];

const CLIENT_ADDRESS_TYPE = [
  {
    value: "Warehouse",
    label: "Entrepot",
  },
  {
    value: "Shop",
    label: "Boutique",
  },
];

export { CLIENT_REGIONS, CLIENT_TYPE, PNZ_CLIENT_STATUS, CLIENT_ADDRESS_TYPE };
