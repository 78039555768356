export const LOADING_SALER_PROFILE_REQUEST = "LOADING_SALER_PROFILE_REQUEST";
export const LOADING_SALER_PROFILE_SUCCESS = "LOADING_SALER_PROFILE_SUCCESS";
export const LOADING_SALER_PROFILE_FAILED = "LOADING_SALER_PROFILE_FAILED";

// export const LOADING_SALERS_REQUEST = "LOADING_SALERS_REQUEST";
// export const LOADING_SALERS_SUCCESS = "LOADING_SALERS_SUCCESS";
// export const LOADING_SALERS_FAILED = "LOADING_SALERS_FAILED";

// export const LOAD_LOGGED_USER = "LOAD_LOGGED_USER";
// export const LOAD_LOGGED_USER_REQUEST = "LOAD_LOGGED_USER_REQUEST";
// export const LOAD_LOGGED_USER_FAILED = "LOAD_LOGGED_USER_FAILED";

export const EDIT_SALER_PROFILE_SUCCESS = "EDIT_SALER_PROFILE_SUCCESS";
export const EDIT_SALER_PROFILE_REQUEST = "EDIT_SALER_PROFILE_REQUEST";
export const EDIT_SALER_PROFILE_FAILED = "EDIT_SALER_PROFILE_FAILED";

export function loadingSalerProfileRequest() {
  return {
    type: LOADING_SALER_PROFILE_REQUEST,
  };
}

export function loadingSalerProfilesSuccess(data) {
  return {
    type: LOADING_SALER_PROFILE_SUCCESS,
    payload: data,
  };
}

export function loadingSalerProfileFailed() {
  return {
    type: LOADING_SALER_PROFILE_FAILED,
  };
}

// edit saler's profile
export function editSalerProfileRequest() {
  return {
    type: EDIT_SALER_PROFILE_REQUEST,
  };
}

export function editSalerProfileSuccess(data) {
  return {
    type: EDIT_SALER_PROFILE_SUCCESS,
    payload: data,
  };
}

export function editSalerProfileFailed(data) {
  return {
    type: EDIT_SALER_PROFILE_FAILED,
    payload: data,
  };
}
