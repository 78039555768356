export const LOADING_USERS_REQUEST = "LOADING_USERS_REQUEST";
export const LOADING_USERS_SUCCESS = "LOADING_USERS_SUCCESS";
export const LOADING_USERS_FAILED = "LOADING_USERS_FAILED";

export const LOADING_SALERS_REQUEST = "LOADING_SALERS_REQUEST";
export const LOADING_SALERS_SUCCESS = "LOADING_SALERS_SUCCESS";
export const LOADING_SALERS_FAILED = "LOADING_SALERS_FAILED";

export const LOAD_LOGGED_USER = "LOAD_LOGGED_USER";
export const LOAD_LOGGED_USER_REQUEST = "LOAD_LOGGED_USER_REQUEST";
export const LOAD_LOGGED_USER_FAILED = "LOAD_LOGGED_USER_FAILED";

export const EDIT_LOGGED_USER_SUCCESS = "EDIT_LOGGED_USER";
export const EDIT_LOGGED_USER_REQUEST = "EDIT_LOGGED_USER_REQUEST";
export const EDIT_LOGGED_USER_FAILED = "EDIT_LOGGED_USER_FAILED";

export function loadingUsersRequest() {
  return {
    type: LOADING_USERS_REQUEST,
  };
}

export function loadingUsersSuccess(data) {
  return {
    type: LOADING_USERS_SUCCESS,
    payload: data,
  };
}

export function loadingUsersFailed() {
  return {
    type: LOADING_USERS_FAILED,
  };
}

// Salers actions
export function loadingSalersRequest() {
  return {
    type: LOADING_SALERS_REQUEST,
  };
}

export function loadingSalersSuccess(data) {
  //   console.log("data ================>", data);
  return {
    type: LOADING_SALERS_SUCCESS,
    payload: data,
  };
}

export function loadingSalersFailed() {
  return {
    type: LOADING_SALERS_FAILED,
  };
}

export function loadLoggedUserRequest() {
  return {
    type: LOAD_LOGGED_USER_REQUEST,
  };
}
export function loadLoggedUserFailed() {
  return {
    type: LOAD_LOGGED_USER_FAILED,
  };
}

export function loggedUser(data) {
  return {
    type: LOAD_LOGGED_USER,
    payload: data,
  };
}

export function editLoggedUserRequest() {
  return {
    type: EDIT_LOGGED_USER_REQUEST,
  };
}

export function editLoggedUserSuccess(data) {
  return {
    type: EDIT_LOGGED_USER_SUCCESS,
    payload: data,
  };
}

export function editLoggedUserFailed(data) {
  return {
    type: EDIT_LOGGED_USER_FAILED,
    payload: data,
  };
}
