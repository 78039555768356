import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {
  Button,
  Box,
  LinearProgress,
  IconButton,
  TextField,
  TablePagination,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadingProducts } from "store/effects/productsEffect";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useTranslation } from "react-i18next";
// core components
import tableStyles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { withStyles } from "@material-ui/styles";
import ContactMailOutlinedIcon from "@material-ui/icons/ContactMailOutlined";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import { loadingClients } from "store/effects/clientsEffects";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  mlAuto: {
    marginLeft: "auto",
  },
  dFlex: {
    display: "flex",
  },
  table: {
    minWidth: 650,
  },
  textSearch: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
  },
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  tableCell: {
    whiteSpace: "nowrap",
  },
};
const SearchTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: "white",
      borderWidth: 2,
    },
    background: '#fff',
    borderRadius: '4px',
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
    "& fieldset": {
      borderColor: "#ffffff",
    },
    "& input": {
      color: "#ffffff",
    },
  },
})(TextField);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles(styles);
const useStyles1 = makeStyles(tableStyles);

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  return (
    <React.Fragment>
      <StyledTableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.first_name + " " + row.last_name}
        </TableCell>
        <TableCell>{row.reference}</TableCell>
        <TableCell>{row.plafond_credit}</TableCell>
        <TableCell>{row.phone_number}</TableCell>
        <TableCell>{row.phone_number2}</TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>{row.region}</TableCell>
        <TableCell>{row.status}</TableCell>
        <TableCell>{row.pzn_client_status}</TableCell>
        <TableCell>
          {row.created_by?.first_name + " " + row.created_by?.last_name}
        </TableCell>
        <TableCell>
          {row.managed_by?.first_name + " " + row.managed_by?.last_name}
        </TableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                {props.trans("Addresses")}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Region</TableCell>
                    <TableCell>{props.trans("Town")}</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Longitude</TableCell>
                    <TableCell>Latitude</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.addresses?.map((address) => (
                    <TableRow key={address.id}>
                      <TableCell component="th" scope="row">
                        {address.region}
                      </TableCell>
                      <TableCell>{address.town}</TableCell>
                      <TableCell>{address.type}</TableCell>
                      <TableCell>{address.longitude}</TableCell>
                      <TableCell>{address.latitude}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ClientsList() {
  const classes = useStyles();
  const classes1 = useStyles1();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { clientList, loading_clients_request } = useSelector(
    (state) => state.clients
  );
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    console.log(event);
    setPage(newPage);
  };
  useEffect(() => {
    dispatch(loadingProducts(""));
    dispatch(loadingClients("", 1));
  }, []);

  useEffect(() => {
    dispatch(loadingClients(search ?? "", page));
  }, [search, page]);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
      <Box sx={{display: 'flex'}}>
          <Box sx={{marginLeft: ''}} mb={2}>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  startIcon={<ContactMailOutlinedIcon />}
                  component={Link}
                  to={"/admin/clients/address/new"}
                  style={{ marginRight: "1rem" }}
                >
                  {t("New_Address")}
                </Button>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  startIcon={<AssignmentIndOutlinedIcon />}
                  component={Link}
                  to={"/admin/clients/new"}
                >
                  {t("New_Client")}
                </Button>
          </Box>
        </Box>
        <Card>
          <CardHeader color="primary">
            <Box className={classes.dFlex}>
              <div>
                <h4 className={classes.cardTitleWhite}>{t("Clients_List")}</h4>
                <p className={classes.cardCategoryWhite}>
                  {t("The_list_of_clients_according_to_your_branch")}
                </p>
              </div>
              <div className={classes.mlAuto}>
                <SearchTextField
                  id="outlined-basic"
                  label={t("Search")}
                  variant="outlined"
                  onChange={handleSearch}
                  style={{ marginRight: "1rem", marginTop: "0px" }}
                  margin="dense"
                />
              </div>
            </Box>
          </CardHeader>
          <CardBody>
            <TableContainer>
              <Table className={classes1.table} aria-label="simple table">
                <TableHead className={classes1["primaryTableHeader"]}>
                  <TableRow className={classes1.tableHeadRow}>
                    <TableCell />
                    <TableCell
                      className={
                        classes1.tableCell +
                        " " +
                        classes1.tableHeadCell +
                        " " +
                        classes.tableCell
                      }
                    >
                      {t("Full_Name")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell +
                        " " +
                        classes1.tableHeadCell +
                        " " +
                        classes.tableCell
                      }
                    >
                      Reference
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell +
                        " " +
                        classes1.tableHeadCell +
                        " " +
                        classes.tableCell
                      }
                    >
                      {t("Limit")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell +
                        " " +
                        classes1.tableHeadCell +
                        " " +
                        classes.tableCell
                      }
                    >
                      {t("Phone_Number")} 1
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell +
                        " " +
                        classes1.tableHeadCell +
                        " " +
                        classes.tableCell
                      }
                    >
                      {t("Phone_Number")} 2
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell +
                        " " +
                        classes1.tableHeadCell +
                        " " +
                        classes.tableCell
                      }
                    >
                      Email
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell +
                        " " +
                        classes1.tableHeadCell +
                        " " +
                        classes.tableCell
                      }
                    >
                      Region
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell +
                        " " +
                        classes1.tableHeadCell +
                        " " +
                        classes.tableCell
                      }
                    >
                      {t("Status")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell +
                        " " +
                        classes1.tableHeadCell +
                        " " +
                        classes.tableCell
                      }
                    >
                      {t("Pnz_client_status")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell +
                        " " +
                        classes1.tableHeadCell +
                        " " +
                        classes.tableCell
                      }
                    >
                      {t("Created_By")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell +
                        " " +
                        classes1.tableHeadCell +
                        " " +
                        classes.tableCell
                      }
                    >
                      {t("Managed_By")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientList.results.map((client) => {
                    return <Row key={client.id} row={client} trans={t} />;
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            {clientList.results.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[25]}
                component="div"
                count={clientList.count}
                rowsPerPage={25}
                page={clientList.page - 1}
                onPageChange={handleChangePage}
              />
            )}
            {loading_clients_request && <LinearProgress />}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
