import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
// core components/views for Admin laypnavpout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";
import Maps from "views/Maps/Maps.js";
import NotificationsPage from "views/Notifications/Notifications.js";
import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// core components/views for RTL layout
import RTLPage from "views/RTLPage/RTLPage.js";
import ProductsList from "views/Products/Products";
import NewProduct from "views/Products/NewProduct";
import NewFormat from "views/Products/NewFormat";
import NewPaymentMethods from "views/Payments/NewPaymentMethod";
import dashboardRoutes from "routes.js";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/logo.png";
import PaymentsMethodsList from "views/Payments/PaymentMethods";
import NewPriceList from "views/Payments/NewPriceList";
import PricesLists from "views/Payments/PricesLists";
import SecureLS from "secure-ls";
import AdminGuard from "./AdminGuard";
import ClientsList from "views/clients/Clients";
import NewClient from "views/clients/NewClient";
import NewAddress from "views/clients/NewAddress";
import Salers from "views/salers/Salers";
import NewSaler from "views/salers/NewSaler";
import SalerDetails from "views/salers/SalerDetails";
// import SalersVisits from "views/salers/Visits";
import NewVisit from "views/salers/NewVisit";
import OrdersList from "views/orders/Orders";
import Visits from "views/Visits/Visits";
import { useTranslation } from "react-i18next";
import UsersList from "views/UsersList/UsersList";
// import AdminGuard from "./AdminGuard";

let ps;
let ls = new SecureLS({ encodingType: "aes" });

const switchRoutes = () => (
  <Switch>
    {/* {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        const Component = prop.component;
        return (
          <Route
            path={prop.layout + prop.path}
            // component={prop.component}
            // exact={true}
            key={key}
            render={(props) => (
              <AdminGuard>
                {prop.children ? (
                  switchRoutes(prop.children)
                ) : (
                  <Component {...props} />
                )}
              </AdminGuard>
            )}
          />
        );
      }
      return null;
    })} */}
    <AdminGuard>
      <Route path={"/admin/dashboard"} component={DashboardPage} />
      <Route path={"/admin/products"} exact component={ProductsList} />
      <Route path={"/admin/products/new"} exact component={NewProduct} />
      <Route path={"/admin/products/formats/new"} exact component={NewFormat} />
      {/* <Redirect from="/admin/payments" to={"/admin/payments/methods"} /> */}
      <Route
        path={"/admin/payments/methods"}
        exact
        component={PaymentsMethodsList}
      />
      <Route
        path={"/admin/payments/prices-list"}
        exact
        component={PricesLists}
      />
      <Route
        path={"/admin/payments/prices-list/new"}
        component={NewPriceList}
      />
      <Route
        path={"/admin/payments/methods/new"}
        exact={true}
        component={NewPaymentMethods}
      />
      {/* Orders routes */}
      <Route path={"/admin/orders"} exact component={OrdersList} />
      {/* Clients routes */}
      <Route path={"/admin/clients"} exact component={ClientsList} />
      <Route path={"/admin/clients/new"} exact component={NewClient} />
      <Route path={"/admin/clients/address/new"} exact component={NewAddress} />
      {/* Salers */}
      <Route path={"/admin/salers"} exact component={Salers} />
      <Route path={"/admin/saler/new"} exact component={NewSaler} />
      <Route
        path={"/admin/saler/:salerId/details"}
        exact
        component={SalerDetails}
      />
      {/* Visits */}
      {/* <Route path={"/admin/salers/visits"} exact component={SalersVisits} /> */}
      <Route path={"/admin/salers/visits/new"} exact component={NewVisit} />
      {/* visits */}
      <Route path={"/admin/visits"} exact component={Visits} />

      {/* Users */}
      <Route path={"/admin/userslist"} exact component={UsersList} />

      {/* Others */}
      <Route path={"/admin/user"} component={UserProfile} />
      {/* <Route path={"/admin/visits"} component={Visits} /> */}
      <Route path={"/admin/table"} component={TableList} />
      <Route path={"/admin/icons"} component={Icons} />
      <Route path={"/admin/maps"} component={Maps} />
      <Route path={"/admin/typography"} component={Typography} />
      <Route path={"/admin/notifications"} component={NotificationsPage} />
      <Route path={"/admin/rtl-page"} component={RTLPage} />
      <Route path={"/admin/upgrade-to-pro"} component={UpgradeToPro} />
    </AdminGuard>
    <Redirect from="/admin/payments" to="/admin/payments/methods" />
    <Redirect from="/admin/" to="/admin/dashboard" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  const { user } = ls.get("user-details");
  const { t } = useTranslation();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  // const [image, setImage] = React.useState(bgImage);
  // const [color, setColor] = React.useState("blue");
  const color = "blue";
  // const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={dashboardRoutes(t)}
        logoText={"SFA - " + user?.branch ?? "PASTA"}
        logo={logo}
        image={bgImage}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      {console.log("master is ====", user)}
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={dashboardRoutes(t)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              {switchRoutes(dashboardRoutes(t))}
            </div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes(dashboardRoutes(t))}</div>
        )}
        {getRoute() ? <Footer /> : null}
        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        /> */}
      </div>
    </div>
  );
}
