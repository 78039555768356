import { toast } from "react-toastify";
import SecureLS from "secure-ls";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls/urls";
import {
  loadingSalersFailed,
  loadingSalersRequest,
  loadingSalersSuccess,
  loadingUsersFailed,
  loadingUsersRequest,
  loadingUsersSuccess,
} from "../actions/usersActions";

var ls = new SecureLS({ encodingType: "aes" });

export function loadingUsers(search) {
  return (dispatch) => {
    dispatch(loadingUsersRequest());
    API.get(URLS.USERS.LIST(search))
      .then((response) => {
        dispatch(loadingUsersSuccess(response.data));
        // history.push('/dashboard');
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ?? "Login error !! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(loadingUsersFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function loadingSalers(search) {
  return (dispatch) => {
    dispatch(loadingSalersRequest());
    API.get(URLS.USERS.SALERS(search))
      .then((response) => {
        dispatch(loadingSalersSuccess(response.data));
        // history.push('/dashboard');
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ?? "Loading error !! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(loadingSalersFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function logout(history) {
  return () => {
    // const idToast = toast.loading("Loging out...");
    ls.removeAll();
    history.push("/");
    window.location.reload();
    // API.get(URLS.AUTHS.LOGOUT, {headers: {"ClientId": URLS.CLIENT_ID}}).then((resp) => {
    //     toast.update(idToast, {render: "Successfully logged out..", type: "success", isLoading: false, autoClose: true});
    //     ls.removeAll();
    //     history.push('/');
    // }).catch((error) => {
    //     ls.removeAll();
    //     history.push('/');
    //     console.log("Error ===============>",  error)
    //     toast.update(idToast, {render: error.response.data.detail ?? "Error while logging ou !! Please try later", type: "error", isLoading: false, autoClose: true });
    // })
  };
}
