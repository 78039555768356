import {
  LOADING_CLIENTS_FAILED,
  LOADING_CLIENTS_REQUEST,
  LOADING_CLIENTS_SUCCESS,
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAILED,
  DELETE_CLIENT_FAILED,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_REQUEST,
  EDIT_CLIENT_FAILED,
  EDIT_CLIENT_REQUEST,
  EDIT_CLIENT_SUCCESS,
  LOAD_CLIENT_FAILED,
  LOAD_CLIENT_REQUEST,
  LOAD_CLIENT_SUCCESS,
  CREATE_ADDRESS_REQUEST,
  CREATE_ADDRESS_SUCCESS,
  CREATE_ADDRESS_FAILED,
} from "../actions/clientsActions";

const initialState = {
  loading_clients_request: false,
  loading_clients_success: false,
  loading_clients_failed: false,
  loading_client_request: false,
  loading_client_success: false,
  loading_client_failed: false,
  create_client_request: false,
  create_client_success: false,
  create_client_failed: false,
  edit_client_request: false,
  edit_client_success: false,
  edit_client_failed: false,
  delete_client_request: false,
  delete_client_success: false,
  delete_client_failed: false,
  create_addresses_request: false,
  create_addresses_success: false,
  create_addresses_failed: false,
  clientList: {
    count: 0,
    pages: 1,
    actualPage: 1,
    results: [],
  },
  client: undefined,
};

export function ClientsReducers(state = initialState, action) {
  switch (action.type) {
    case LOADING_CLIENTS_REQUEST:
      return {
        ...state,
        loading_clients_request: true,
        loading_clients_success: false,
        loading_clients_failed: false,
      };
    case LOADING_CLIENTS_SUCCESS:
      return {
        ...state,
        loading_clients_request: false,
        loading_clients_success: true,
        loading_clients_failed: false,
        clientList: action.payload.isAdmin
          ? { ...state.clientList, ...action.payload.data }
          : { ...state.clientList, ...action.payload.data },
      };
    case LOADING_CLIENTS_FAILED:
      return {
        ...state,
        loading_clients_request: false,
        loading_clients_success: false,
        loading_clients_failed: true,
      };
    case LOAD_CLIENT_REQUEST:
      return {
        ...state,
        loading_client_request: true,
        loading_client_success: false,
        loading_client_failed: false,
      };
    case LOAD_CLIENT_SUCCESS:
      return {
        ...state,
        loading_client_request: false,
        loading_client_success: true,
        loading_client_failed: false,
        client: action.payload,
      };
    case LOAD_CLIENT_FAILED:
      return {
        ...state,
        loading_client_request: false,
        loading_client_success: false,
        loading_client_failed: true,
      };
    case CREATE_CLIENT_REQUEST:
      return {
        ...state,
        create_client_request: true,
        create_client_success: false,
        create_client_failed: false,
      };
    case CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        create_client_request: false,
        create_client_success: true,
        create_client_failed: false,
        // clientList: {
        //     ...state.clientList,
        //     results: [action.payload, ...state.clientList.results]
        // },
      };
    case CREATE_CLIENT_FAILED:
      return {
        ...state,
        create_client_request: false,
        create_client_success: false,
        create_client_failed: true,
      };

    case EDIT_CLIENT_REQUEST:
      return {
        ...state,
        edit_client_request: true,
        edit_client_success: false,
        edit_client_failed: false,
      };
    case EDIT_CLIENT_SUCCESS:
      return {
        ...state,
        edit_client_request: false,
        edit_client_success: true,
        edit_client_failed: false,
        clientList: {
          ...state.clientList,
          results: state.clientList.results.map((item) => {
            if (item.id === action.id) {
              item = action.payload.data;
            }

            return item;
          }),
        },
      };
    case EDIT_CLIENT_FAILED:
      return {
        ...state,
        edit_client_request: false,
        edit_client_success: false,
        edit_client_failed: true,
      };

    case DELETE_CLIENT_REQUEST:
      return {
        ...state,
        delete_client_request: true,
        delete_client_success: false,
        delete_client_failed: false,
      };
    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        delete_client_request: false,
        delete_client_success: true,
        delete_client_failed: false,
        clientList: {
          ...state.clientList,
          results: state.clientList.results.filter(
            (item) => item.id != action.payload.id
          ),
        },
      };
    case DELETE_CLIENT_FAILED:
      return {
        ...state,
        create_client_request: false,
        create_client_success: false,
        create_client_failed: true,
      };
    case CREATE_ADDRESS_REQUEST:
      return {
        ...state,
        create_addresses_request: true,
        create_addresses_success: false,
        create_addresses_failed: false,
      };
    case CREATE_ADDRESS_SUCCESS:
      return {
        ...state,
        create_addresses_request: false,
        create_addresses_success: true,
        create_addresses_failed: false,
      };
    case CREATE_ADDRESS_FAILED:
      return {
        ...state,
        create_addresses_request: false,
        create_addresses_success: false,
        create_addresses_failed: true,
      };
    default:
      return state;
  }
}
