import axios from "axios";
import SecureLS from "secure-ls";
import { URLS } from "./urls/urls";
import { refreshToken } from "./refresh_token";

var ls = new SecureLS({ encodingType: "aes" });

const user = ls.get("user-details");

const API = axios.create({
  baseURL: URLS.BASE_URL,
  timeout: 100000,
  headers: { Authorization: `Bearer ${user.access_token}` },
});

const AUTHAPI = axios.create({
  baseURL: URLS.BASE_URL,
  headers: { Authorization: `Bearer ${user.access_token}` },
});

API.interceptors.request.use(
  // intercept the API request instance created above
  async (config) => {
    // const session = token;
    const token = user.access_token;

    if (token) {
      //checks if there is token
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`, //if yes store the token in header
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);
// Response interceptor for API calls
API.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config; //store error.config in originalRequest
    if (
      error.response.status === 403 ||
      (error.response.status === 401 && !originalRequest._retry) // check reponse status if it has expired
    ) {
      originalRequest._retry = true; // update originalRequest._retry to true
      const token = ls.get("accessToken");
      const access_token = await refreshToken();
      console.log("originalRequest ===> ", token);
      console.log(access_token, token);
      // CONTACTAPI.defaults.headers.common['Authorization'] = access_token;
      originalRequest.headers = {
        ...originalRequest.headers,
        Authorization: `Bearer ${access_token}`,
      };
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

export { API, AUTHAPI };
