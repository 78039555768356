import { useTranslation } from "react-i18next";
import routes from "routes";

export const useRouteName = () => {
  const { t } = useTranslation();
  let name = "";
  let path = "";
  routes(t).forEach((route) => {
    if (window.location.href.indexOf(route.layout + route.path) !== -1) {
      name = routes(t).rtlActive ? route.rtlName : route.name;
      path = route.path;
    }
  });

  return [name, path];
};
