// core components
// import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
// Formik form
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody";
import { useDispatch, useSelector } from "react-redux";
import { loadingProducts } from "store/effects/productsEffect";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CLIENT_ADDRESS_TYPE } from "consts/clients";
import { theme } from "components/Theme/Theme";
import { loadingClients, createAddress } from "store/effects/clientsEffects";
import { useTranslation } from "react-i18next";

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  uploadButton: {
    "& span": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
};

const useStyles = makeStyles(styles);
const validationSchema = yup.object({
  region: yup.string("Enter the region").required("Region is required"),
  town: yup.string("Enter the town").required("The town is required"),
  type: yup
    .string("Enter the type")
    .required("The client's reference is required"),
  logitude: yup
    .number("Enter logitude")
    .required("The client's email is required"),
  latitude: yup.number("Enter latitude").required("The latitude is required"),
});

export default function NewAddress(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    create_client_request,
    clientList: clientsList,
    loading_clients_request,
  } = useSelector((state) => state.clients);
  const [client, setClient] = useState();
  const [image, setImage] = useState();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      region: "",
      town: "",
      type: "",
      logitude: "",
      latitude: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
      let formData = new FormData();
      formData.append("region", values.region);
      formData.append("town", values.town);
      formData.append("type", values.type);
      formData.append("logitude", values.logitude);
      formData.append("latitude", values.latitude);
      if (image) {
        formData.append("image", image);
      }
      if (client) {
        formData.append("client", client.id);
      }
      dispatch(createAddress(formData, props.history));
    },
  });
  const handleInputImage = (event) => {
    const files = event.target.files[0];
    setImage(files);
  };
  const handleSearchSaler = (value) => {
    dispatch(loadingClients(value ?? ""));
  };
  useEffect(() => {
    dispatch(loadingProducts(""));
    dispatch(loadingClients(""));
  }, []);
  return (
    <GridContainer>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{t("New_Address_caps")}</h4>
          <p className={classes.cardCategoryWhite}>
            {t("You_are_creating_a_client_address")}
          </p>
        </CardHeader>
        <CardBody>
          <Container>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="region"
                    name="region"
                    label="Region *"
                    variant="outlined"
                    value={formik.values.region}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.region && Boolean(formik.errors.region)
                    }
                    helperText={formik.touched.region && formik.errors.region}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="town"
                    name="town"
                    label={t("Town")}
                    variant="outlined"
                    value={formik.values.town}
                    onChange={formik.handleChange}
                    error={formik.touched.town && Boolean(formik.errors.town)}
                    helperText={formik.touched.town && formik.errors.town}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="logitude"
                    name="logitude"
                    label="Logitude *"
                    variant="outlined"
                    value={formik.values.logitude}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.logitude && Boolean(formik.errors.logitude)
                    }
                    helperText={
                      formik.touched.logitude && formik.errors.logitude
                    }
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="latitude"
                    name="latitude"
                    label="Latitude"
                    variant="outlined"
                    value={formik.values.latitude}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.latitude && Boolean(formik.errors.latitude)
                    }
                    helperText={
                      formik.touched.latitude && formik.errors.latitude
                    }
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="type"
                    name="type"
                    label={t("Address_Type")}
                    select
                    variant="outlined"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                  >
                    {CLIENT_ADDRESS_TYPE.map((region) => {
                      return (
                        <MenuItem key={region.value} value={region.value}>
                          {region.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item md={6}>
                  <Autocomplete
                    value={client}
                    onChange={(event, newValue) => {
                      setClient(newValue);
                    }}
                    loading={loading_clients_request}
                    onInputChange={(event, newInputValue) => {
                      handleSearchSaler(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={clientsList.results}
                    // style={{ width: 300 }}
                    getOptionLabel={(option) =>
                      `${option.first_name} ${option.last_name}`
                    }
                    renderOption={(option) => (
                      <React.Fragment>
                        {" "}
                        {option.first_name + " " + option.last_name}{" "}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Select_a_Client")}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12}>
                  <input
                    accept="image/*"
                    hidden
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleInputImage}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      className={classes.uploadButton}
                      variant="outlined"
                      color="primary"
                      component="span"
                    >
                      {t("Address_Image")}
                    </Button>
                  </label>
                  {image && <p>{image.name}</p>}
                </Grid>
                <Grid item>
                  <Button
                    disabled={create_client_request}
                    type={"submit"}
                    variant="contained"
                    color="primary"
                  >
                    {t("Create_address")}{" "}
                    {create_client_request && <CircularProgress size={14} />}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
