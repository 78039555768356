import React from "react";
import {
  Grid,
  Avatar,
  TextField,
  Button,
  Typography,
  Link,
  Checkbox,
  Box,
  Paper,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "store/actions/AuthActions";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    textAlign: "center",
    width: "100%",
  },
  avatar: {
    margin: "auto",
    backgroundColor: theme.palette.primary.main,
  },
  gridContainer: {
    justifyContent: "center",
  },
  illustration: {
    backgroundColor: "center",
  },
  main: {
    backgroundImage:
      "url(http://cadyst.com/wp-content/uploads/2022/04/31082021-DSC01553-min-scaled.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

function Copyright(props) {
  return (
    <Typography variant="body2" color="secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://cadyst.com/">
        Cadyst
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const Login = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showLoading } = useSelector((state) => state.auth);
  // const val = useSelector((state) => state.auth);
  // console.log(val);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");
    dispatch(loginAction(email, password, props.history));
  };
  return (
    <Grid className={classes.main}>
      <Grid container className={classes.gridContainer}>
        <Grid item md={6} sm={6} xs={12}>
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "95vh",
            }}
          >
            <Paper elevation={0} className={classes.paper}>
              <Grid container>
                <Grid item md={6} xs={12} className={classes.illustration}>
                  <img src="/login-illustration.jpeg" width={"100%"} alt="" />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box sx={{ display: "flex", height: "100%" }}>
                    <Box sx={{ margin: "auto" }}>
                      <Avatar sx={{ m: 1 }} className={classes.avatar}>
                        <LockOutlinedIcon />
                      </Avatar>
                      <Typography component="h1" variant="h5">
                        Sale For Automation
                      </Typography>
                      <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{ mt: 1 }}
                      >
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          autoComplete="email"
                          autoFocus
                        />
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          autoComplete="current-password"
                        />
                        {/* <FormControlLabel
                          control={<Checkbox value="remember" color="primary" />}
                          label="Remember me"
                        /> */}
                        <div style={{ marginTop: "20px" }}></div>
                        <Button
                          type="submit"
                          disabled={showLoading}
                          fullWidth
                          variant="contained"
                          color="primary"
                          sx={{ mt: 3, mb: 2 }}
                        >
                          Sign In
                        </Button>
                        <Grid container>
                          <Grid item md={12}>
                            <Link href="#" variant="body2">
                              Forgot password?
                            </Link>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Grid>
      </Grid>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Grid>
  );
};

export default Login;
