// core components
// import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
// Formik form
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody";
import { useDispatch, useSelector } from "react-redux";
import { loadingProducts } from "store/effects/productsEffect";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CLIENT_REGIONS, PNZ_CLIENT_STATUS, CLIENT_TYPE } from "consts/clients";
import { theme } from "components/Theme/Theme";
import { loadingSalers } from "store/effects/usersEffect";
import { createClient } from "store/effects/clientsEffects";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";


const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  uploadButton: {
    "& span": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
};

const useStyles = makeStyles(styles);
const validationSchema = (t) =>
  yup.object({
    first_name: yup
      .string(t("Enter_the_clients_first_name"))
      .required(t("First_name_is_required")),
    last_name: yup
      .string(t("Enter_the_clients_last_name"))
      .required(t("Last_name_is_required")),
    reference: yup
      .string(t("Enter_the_clients_reference"))
      .required(t("The_clients_reference_is_required")),
    email: yup
      .string(t("Enter_clients_email"))
      .email(t("Enter_clients_email"))
      .required(t("The_clients_email_is_required")),
    phone_number: yup
      .string(t("Enter_clients_phone_number"))
      .required(t("The_clients_phone_number_is_required")),
    phone_number2: yup.string(t("Enter_clients_phone_number_2")),
    // region: yup
    //   .string(t("Enter_clients_region"))
    //   .required(t("The_clients_region_is_required")),
    // pzn_client_status: yup
    //   .string(t("Enter_panzani_clients_status"))
    //   .required(t("The_panzani_clients_status_is_required")),
    status: yup
      .string(t("Enter_clients_status"))
      .required(t("The_clients_status_is_required")),
    plafond_credit: yup
      .string(t("Enter_clients_plafond"))
      .required(t("The_clients_plafond_is_required")),
  });

export default function NewClient(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    productsList: { results },
    loading_products_request,
  } = useSelector((state) => state.products);
  const { usersList, loading_salers_request } = useSelector(
    (state) => state.users
  );
  const { user } = useSelector((state) => state.auth.auth);
  const { create_client_request } = useSelector((state) => state.clients);
  const [value, setValue] = useState();
  const [saler, setSaler] = useState();
  const [image, setImage] = useState();
  const dispatch = useDispatch();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      reference: "",
      email: "",
      phone_number: "",
      phone_number2: "",
      region: "",
      pzn_client_status: "",
      status: "",
      plafond_credit: "",
    },
    validationSchema: validationSchema(t),
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
      let formData = new FormData();
      formData.append("first_name", values.first_name);
      formData.append("reference", values.reference);
      formData.append("last_name", values.last_name);
      formData.append("email", values.email);
      formData.append("phone_number", values.phone_number);
      formData.append("phone_number2", values.phone_number2);
      formData.append("region", values.region);
      formData.append("pzn_client_status", values.pzn_client_status);
      formData.append("plafond_credit", values.plafond_credit);
      formData.append("status", values.status);
      if (image) {
        formData.append("image", image);
      }
      if (saler) {
        formData.append("managed_by", saler.id);
      }
      if (user) {
        formData.append("created_by", user.id);
      }
      dispatch(createClient(formData, history));
    },
  });
  const handleInputImage = (event) => {
    const files = event.target.files[0];
    setImage(files);
  };
  const handleSearch = (value) => {
    dispatch(loadingProducts(value ?? ""));
  };
  const handleSearchSaler = (value) => {
    dispatch(loadingProducts(value ?? ""));
  };
  useEffect(() => {
    dispatch(loadingProducts(""));
    dispatch(loadingSalers(""));
  }, []);
  return (
    <GridContainer>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{t("New_client")}</h4>
          <p className={classes.cardCategoryWhite}>
            {t("You_are_creating_a_client")}
          </p>
        </CardHeader>
        <CardBody>
          <Container>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="first_name"
                    name="first_name"
                    label={t("First_Name") + " *"}
                    variant="outlined"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.first_name &&
                      Boolean(formik.errors.first_name)
                    }
                    helperText={
                      formik.touched.first_name && formik.errors.first_name
                    }
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="last_name"
                    name="last_name"
                    label={t("Last_Name") + " *"}
                    variant="outlined"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.last_name &&
                      Boolean(formik.errors.last_name)
                    }
                    helperText={
                      formik.touched.last_name && formik.errors.last_name
                    }
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    variant="outlined"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="reference"
                    name="reference"
                    label="Reference"
                    variant="outlined"
                    value={formik.values.reference}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.reference &&
                      Boolean(formik.errors.reference)
                    }
                    helperText={
                      formik.touched.reference && formik.errors.reference
                    }
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="phone_number"
                    name="phone_number"
                    label={t("Phone_Number") + " *"}
                    variant="outlined"
                    value={formik.values.phone_number}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phone_number &&
                      Boolean(formik.errors.phone_number)
                    }
                    helperText={
                      formik.touched.phone_number && formik.errors.phone_number
                    }
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="phone_number2"
                    name="phone_number2"
                    label={t("Phone_Number") + " 2"}
                    variant="outlined"
                    value={formik.values.phone_number2}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phone_number2 &&
                      Boolean(formik.errors.phone_number2)
                    }
                    helperText={
                      formik.touched.phone_number2 &&
                      formik.errors.phone_number2
                    }
                  />
                </Grid>

                {user?.branch == "PANZANI" && (
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      id="region"
                      name="region"
                      label="Region *"
                      select
                      variant="outlined"
                      value={formik.values.region}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.region && Boolean(formik.errors.region)
                      }
                      helperText={formik.touched.region && formik.errors.region}
                    >
                      {CLIENT_REGIONS.map((region) => {
                        return (
                          <MenuItem key={region.value} value={region.value}>
                            {region.label}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                )}
                {user?.branch == "PANZANI" && (
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      id="pzn_client_status"
                      name="pzn_client_status"
                      label={t("Pnz_client_status")}
                      variant="outlined"
                      select
                      value={formik.values.pzn_client_status}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.pzn_client_status &&
                        Boolean(formik.errors.pzn_client_status)
                      }
                      helperText={
                        formik.touched.pzn_client_status &&
                        formik.errors.pzn_client_status
                      }
                    >
                      {PNZ_CLIENT_STATUS.map((status) => {
                        return (
                          <MenuItem key={status.value} value={status.value}>
                            {status.label}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                )}
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="status"
                    name="status"
                    label={t("Status") + " *"}
                    variant="outlined"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    select
                    error={
                      formik.touched.status && Boolean(formik.errors.status)
                    }
                    helperText={formik.touched.status && formik.errors.status}
                  >
                    {CLIENT_TYPE.map((status) => {
                      return (
                        <MenuItem key={status.value} value={status.value}>
                          {status.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="plafond_credit"
                    name="plafond_credit"
                    label={t("Limit") + "/" + t("credit") + " *"}
                    variant="outlined"
                    value={formik.values.plafond_credit}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.plafond_credit &&
                      Boolean(formik.errors.plafond_credit)
                    }
                    helperText={
                      formik.touched.plafond_credit &&
                      formik.errors.plafond_credit
                    }
                  />
                </Grid>
                <Grid item md={6}>
                  <Autocomplete
                    value={saler}
                    onChange={(event, newValue) => {
                      setSaler(newValue);
                    }}
                    loading={loading_salers_request}
                    onInputChange={(event, newInputValue) => {
                      handleSearchSaler(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={usersList.results}
                    // style={{ width: 300 }}
                    getOptionLabel={(option) =>
                      `${option.first_name} ${option.last_name}`
                    }
                    renderOption={(option) => (
                      <React.Fragment>
                        {" "}
                        {option.first_name + " " + option.last_name}{" "}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Select_a_Saler")}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Autocomplete
                    hidden
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                      console.log("newValue ========>", newValue);
                    }}
                    loading={loading_products_request}
                    // inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      handleSearch(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={results}
                    // style={{ width: 300 }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option) => (
                      <React.Fragment> {option.name} </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select a product *"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12}>
                  <input
                    accept="image/*"
                    hidden
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleInputImage}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      className={classes.uploadButton}
                      variant="outlined"
                      color="primary"
                      component="span"
                    >
                      {t("Client_contract")}
                    </Button>
                  </label>
                  {image && <p>{image.name}</p>}
                </Grid>
                <Grid item>
                  <Button
                    disabled={create_client_request}
                    type={"submit"}
                    variant="contained"
                    color="primary"
                  >
                    {t("Add_new_client")}{" "}
                    {create_client_request && <CircularProgress size={14} />}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
