import { EDIT_VISITS_REQUEST } from "store/actions/VisitsActions";
import { EDIT_VISITS_SUCCESS } from "store/actions/VisitsActions";
import { EDIT_VISITS_FAILED } from "store/actions/VisitsActions";
import { LOAD_VISITS_FAILED } from "store/actions/VisitsActions";
import { LOAD_VISITS_SUCCESS } from "store/actions/VisitsActions";
import { LOAD_VISITS_REQUEST } from "store/actions/VisitsActions";

const initialState = {
  loading_visits_request: false,
  loading_visits_success: false,
  loading_visits_failed: false,
  user: {
    count: 0,
    pages: 1,
    actualPage: 1,
    results: [],
  },
  //   Inventories: [],
};

const initialState2 = {
  edit_visits_request: false,
  edit_visits_success: false,
  edit_visits_failed: false,
  user2: undefined,
};

export function visitsReducers(state = initialState, action) {
  switch (action.type) {
    case LOAD_VISITS_REQUEST:
      return {
        ...state,
        loading_visits_request: true,
        loading_visits_success: false,
        loading_visits_failed: false,
      };
    case LOAD_VISITS_SUCCESS:
      return {
        ...state,
        loading_visits_request: false,
        loading_visits_success: true,
        loading_visits_failed: false,
        user: { ...state.user.results, ...action.payload },
        // user: action.payload,
      };
    case LOAD_VISITS_FAILED:
      return {
        ...state,
        loading_visits_request: false,
        loading_visits_success: false,
        loading_visits_failed: true,
      };
    default:
      return state;
  }
}

export function editVisitsReducers(state = initialState2, action) {
  switch (action.type) {
    case EDIT_VISITS_REQUEST:
      return {
        ...state,
        edit_visits_request: true,
        edit_visits_success: false,
        edit_visits_failed: false,
      };
    case EDIT_VISITS_SUCCESS:
      return {
        ...state,
        edit_visits_request: false,
        edit_visits_success: true,
        edit_visits_failed: false,
        user2: action.payload,
      };
    case EDIT_VISITS_FAILED:
      return {
        ...state,
        edit_visits_request: false,
        edit_visits_success: false,
        edit_visits_failed: true,
      };
    default:
      return state;
  }
}
