import { toast } from "react-toastify";
import { loadVisitsSuccess } from "store/actions/VisitsActions";
import { loadVisitsFailed } from "store/actions/VisitsActions";
import { loadVisitsRequest } from "store/actions/VisitsActions";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls/urls";

export function loadingVisits(search) {
  return (dispatch) => {
    dispatch(loadVisitsRequest());
    API.get(URLS.VISITS.LIST(search))
      .then((response) => {
        dispatch(loadVisitsSuccess(response.data, true));
        // history.push("/dashboard");
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          error.response.data.detail ??
            "Loading transaction error !! Please try later"
        );

        dispatch(loadVisitsFailed());
      });
  };
}
