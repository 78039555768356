import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {
  // Button,
  Box,
  LinearProgress,
  IconButton,
  TextField,
  TablePagination,
} from "@material-ui/core";
// import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadingProducts } from "store/effects/productsEffect";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// core components
import tableStyles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { withStyles } from "@material-ui/styles";

import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { loadingVisits } from "store/effects/visits";
import { useTranslation } from "react-i18next";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  mlAuto: {
    marginLeft: "auto",
  },
  dFlex: {
    display: "flex",
  },
  table: {
    minWidth: 650,
  },
  textSearch: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
  },
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  tableCell: {
    whiteSpace: "nowrap",
  },
};
const SearchTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: "white",
      borderWidth: 2,
    },
    background: '#fff',
    borderRadius: '4px',
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important",
    },
    "& fieldset": {
      borderColor: "#ffffff",
    },
    "& input": {
      color: "#ffffff",
    },
  },
})(TextField);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles(styles);
const useStyles1 = makeStyles(tableStyles);

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  return (
    <React.Fragment>
      <StyledTableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.object}
        </TableCell>
        <TableCell>{row.address.town + " " + row.address.region}</TableCell>
        <TableCell>{row.date}</TableCell>
        <TableCell>{row.saler?.first_name}</TableCell>
        <TableCell>{`${row.is_completed}`}</TableCell>
        <TableCell>{row.description}</TableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                {props.trans("Inventories")}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>{props.trans("Previous_Quantity")}</TableCell>
                    <TableCell>{props.trans("Current_Quantity")}</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>{props.trans("Product")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.inventories?.map((inventory) => (
                    <TableRow key={inventory.id}>
                      <TableCell component="th" scope="row">
                        {inventory.previous_quantity}
                      </TableCell>
                      <TableCell>{inventory.current_quantity}</TableCell>
                      <TableCell>{inventory.description}</TableCell>
                      <TableCell>{inventory.product.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function Visits() {
  const classes = useStyles();
  const classes1 = useStyles1();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    user,
    loading_visits_request,
    //  loading_visits_success
  } = useSelector((state) => state.visitsList);
  // const [visits, setVisits] = useState({});

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    console.log(event);
    setPage(newPage);
  };
  useEffect(() => {
    dispatch(loadingProducts(""));

    dispatch(loadingVisits(""));
  }, []);

  useEffect(() => {
    dispatch(loadingVisits(search ?? ""));
  }, [search, page]);

  // useEffect(() => {
  //   if (loading_visits_success) {
  //     setVisits(user);
  //   }
  // }, [loading_visits_success]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <Box className={classes.dFlex}>
              <div>
                <h4 className={classes.cardTitleWhite}>{t("visits_list")}</h4>
                <p className={classes.cardCategoryWhite}>
                  {t("The_list_of_visits_according_to_your_branch")}
                </p>
                {/* <button onClick={() => console.log(visits)}>cmd</button> */}
              </div>
              <div className={classes.mlAuto}>
                <SearchTextField
                  id="outlined-basic"
                  label={t("Search")}
                  variant="outlined"
                  onChange={handleSearch}
                  style={{ marginRight: "1rem", marginTop: "0px" }}
                  margin="dense"
                />
              </div>
            </Box>
          </CardHeader>
          <CardBody>
            <TableContainer>
              <Table className={classes1.table} aria-label="simple table">
                <TableHead className={classes1["primaryTableHeader"]}>
                  <TableRow className={classes1.tableHeadRow}>
                    <TableCell />
                    <TableCell
                      className={
                        classes1.tableCell +
                        " " +
                        classes1.tableHeadCell +
                        " " +
                        classes.tableCell
                      }
                    >
                      {t("Object")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell +
                        " " +
                        classes1.tableHeadCell +
                        " " +
                        classes.tableCell
                      }
                    >
                      {t("Address")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell +
                        " " +
                        classes1.tableHeadCell +
                        " " +
                        classes.tableCell
                      }
                    >
                      Date
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell +
                        " " +
                        classes1.tableHeadCell +
                        " " +
                        classes.tableCell
                      }
                    >
                      {t("Saler")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell +
                        " " +
                        classes1.tableHeadCell +
                        " " +
                        classes.tableCell
                      }
                    >
                      {t("Is_Completed")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell +
                        " " +
                        classes1.tableHeadCell +
                        " " +
                        classes.tableCell
                      }
                    >
                      Description
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {user.results.map((us) => {
                    return <Row key={us.id} row={us} trans={t} />;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {user.results.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[25]}
                component="div"
                count={user.count}
                rowsPerPage={25}
                page={user.page - 1}
                onPageChange={handleChangePage}
              />
            )}
            {loading_visits_request && <LinearProgress />}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
