export const LOADING_PRICES_LISTS_REQUEST = "LOADING_PRICES_LISTS_REQUEST";
export const LOADING_PRICES_LISTS_SUCCESS = "LOADING_PRICES_LISTS_SUCCESS";
export const LOADING_PRICES_LISTS_FAILED = "LOADING_PRICES_LISTS_FAILED";

export const CREATE_PRICES_LIST_REQUEST = "CREATE_PRICES_LIST_REQUEST";
export const CREATE_PRICES_LIST_SUCCESS = "CREATE_PRICES_LIST_SUCCESS";
export const CREATE_PRICES_LIST_FAILED = "CREATE_PRICES_LIST_FAILED";

export const EDIT_PRICES_LIST_REQUEST = "EDIT_PRICES_LIST_REQUEST";
export const EDIT_PRICES_LIST_SUCCESS = "EDIT_PRICES_LIST_SUCCESS";
export const EDIT_PRICES_LIST_FAILED = "EDIT_PRICES_LIST_FAILED";

export const DELETE_PRICES_LIST_REQUEST = "DELETE_PRICES_LIST_REQUEST";
export const DELETE_PRICES_LIST_SUCCESS = "DELETE_PRICES_LIST_SUCCESS";
export const DELETE_PRICES_LIST_FAILED = "DELETE_PRICES_LIST_FAILED";

export const LOAD_PRICES_LIST_REQUEST = "LOAD_PRICES_LIST_REQUEST";
export const LOAD_PRICES_LIST_SUCCESS = "LOAD_PRICES_LIST_SUCCESS";
export const LOAD_PRICES_LIST_FAILED = "LOAD_PRICES_LIST_FAILED";

export function loadingPricesListsRequest() {
  return {
    type: LOADING_PRICES_LISTS_REQUEST,
  };
}

export function loadingPricesListsSuccess(data, isAdmin) {
  return {
    type: LOADING_PRICES_LISTS_SUCCESS,
    payload: { data, isAdmin },
  };
}

export function loadingPricesListsFailed() {
  return {
    type: LOADING_PRICES_LISTS_FAILED,
  };
}

// Create payment action

export function createPricesListRequest() {
  return {
    type: CREATE_PRICES_LIST_REQUEST,
  };
}

export function createPricesListSuccess(data) {
  return {
    type: CREATE_PRICES_LIST_SUCCESS,
    payload: data,
  };
}

export function createPricesListFailed() {
  return {
    type: CREATE_PRICES_LIST_FAILED,
  };
}

// Edit payment actions

export function editPricesListRequest() {
  return {
    type: EDIT_PRICES_LIST_REQUEST,
  };
}

export function editPricesListSuccess(id, data) {
  return {
    type: EDIT_PRICES_LIST_SUCCESS,
    payload: { id, data },
  };
}

export function editPricesListFailed() {
  return {
    type: EDIT_PRICES_LIST_FAILED,
  };
}

// Delete PricesList actions

export function deletePricesListRequest() {
  return {
    type: DELETE_PRICES_LIST_REQUEST,
  };
}

export function deletePricesListSuccess(id) {
  return {
    type: DELETE_PRICES_LIST_SUCCESS,
    payload: { id: id },
  };
}

export function deletePricesListFailed() {
  return {
    type: DELETE_PRICES_LIST_FAILED,
  };
}
// Load PricesList actions

export function loadPricesListRequest() {
  return {
    type: LOAD_PRICES_LIST_REQUEST,
  };
}

export function loadPricesListSuccess(payment) {
  return {
    type: LOAD_PRICES_LIST_SUCCESS,
    payload: payment,
  };
}

export function loadPricesListFailed() {
  return {
    type: LOAD_PRICES_LIST_FAILED,
  };
}
