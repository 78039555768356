import { toast } from "react-toastify";
import { API } from "services/axios";
import { URLS } from "../../services/urls/urls";
import {
  editLoggedUserFailed,
  editLoggedUserRequest,
  editLoggedUserSuccess,
  loadLoggedUserFailed,
  loadLoggedUserRequest,
  loggedUser,
} from "../actions/usersActions";

export function getLoggedUser() {
  return (dispatch) => {
    dispatch(loadLoggedUserRequest());
    API.get(URLS.USERS.USER.ME)
      .then((response) => {
        dispatch(loggedUser(response.data));
        console.log("sdoir");
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ?? "Loading user error!! Please try later"
        );

        dispatch(loadLoggedUserFailed());
      });
  };
}

export function editLoggedUser(id, data, history) {
  return (dispatch) => {
    dispatch(editLoggedUserRequest());
    API.patch(URLS.USERS.USER.EDIT(id), data)

      .then((response) => {
        dispatch(editLoggedUserSuccess(id, response.data));
        history.push("/admin/products");
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ?? "user edit failed!! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(editLoggedUserFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}
