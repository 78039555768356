import { toast } from "react-toastify";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls/urls";
import {
  loadingPricesListsFailed,
  loadingPricesListsRequest,
  loadingPricesListsSuccess,
  createPricesListFailed,
  createPricesListRequest,
  createPricesListSuccess,
  deletePricesListFailed,
  deletePricesListRequest,
  deletePricesListSuccess,
  editPricesListFailed,
  editPricesListRequest,
  editPricesListSuccess,
  loadPricesListFailed,
  loadPricesListRequest,
  loadPricesListSuccess,
} from "../actions/PriceListActions";

export function loadingPricesLists(isAdmin) {
  return (dispatch) => {
    dispatch(loadingPricesListsRequest());
    API.get(URLS.PRICE_LIST.LIST)
      .then((response) => {
        dispatch(loadingPricesListsSuccess(response.data, isAdmin));
        // history.push('/dashboard');
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ??
            "Error while loading !! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(loadingPricesListsFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function createPricesList(data, history) {
  return (dispatch) => {
    dispatch(createPricesListRequest());
    API.post(URLS.PRICE_LIST.CREATE, data)
      .then((response) => {
        toast.success("Price list successfully created.");
        const { data } = response;
        dispatch(createPricesListSuccess(data));
        history.push("/admin/payments/prices-list");
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ??
            " PricesList creation error!! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(createPricesListFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function editPricesList(id, data, history) {
  return (dispatch) => {
    dispatch(editPricesListRequest());
    API.put(URLS.PRICE_LIST.EDIT(id), data)
      .then((response) => {
        dispatch(editPricesListSuccess(id, response.data));
        history.push("/admin/trades");
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ??
            "PricesList edit failed!! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(editPricesListFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

// Close position

export function closePricesList(
  id,
  data,
  history,
  resetForm,
  handleCloseModal
) {
  return (dispatch) => {
    const idToast = toast.loading("Closing trade ...");
    API.put(URLS.PRICE_LIST.CLOSE(id), data)
      .then(() => {
        toast.update(idToast, {
          render: "PricesList closed successfully",
          type: "success",
          isLoading: false,
        });
        dispatch(deletePricesListSuccess(id));
        resetForm();
        handleCloseModal();
        history.push("/admin/trades");
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.update(idToast, {
          render:
            error.response.data.detail ??
            "PricesList closing failed !! Please try later",
          type: "error",
          isLoading: false,
          autoClose: true,
        });
        // const errorMessage = formatError(error.response.data);
        dispatch(deletePricesListFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

// Delete wallet effect
export function deletePricesList(id, history) {
  return (dispatch) => {
    dispatch(deletePricesListRequest());
    const idToast = toast.loading("Please wait...");
    API.delete(URLS.PRICE_LIST.DELETE(id))
      .then(() => {
        toast.update(idToast, {
          render: "PricesList deleted successfully",
          type: "success",
          isLoading: false,
        });
        dispatch(deletePricesListSuccess(id));
        history.push("/admin/trades");
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.update(idToast, {
          render:
            error.response.data.detail ??
            "PricesList deletion failed !! Please try later",
          type: "error",
          isLoading: false,
          autoClose: true,
        });
        // const errorMessage = formatError(error.response.data);
        dispatch(deletePricesListFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

// Load wallet effect
export function loadPricesList(id) {
  return (dispatch) => {
    dispatch(loadPricesListRequest());
    API.get(URLS.PRICE_LIST.GET(id))
      .then((response) => {
        dispatch(loadPricesListSuccess(response.data));
        // history.push('/dashboard');
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ??
            "Loading wallet error!! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(loadPricesListFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}
