import { toast } from "react-toastify";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls/urls";
import {
  loadingProductsFailed,
  loadingProductsRequest,
  loadingProductsSuccess,
  createProductFailed,
  createProductRequest,
  createProductSuccess,
  deleteProductFailed,
  deleteProductRequest,
  deleteProductSuccess,
  editProductFailed,
  editProductRequest,
  editProductSuccess,
  loadProductFailed,
  loadProductRequest,
  loadProductSuccess,
  loadingFormatsRequest,
  loadingFormatsSuccess,
  loadingFormatsFailed,
  createFormatRequest,
  createFormatSuccess,
  createFormatFailed,
  editFormatRequest,
  editFormatSuccess,
  deleteFormatRequest,
  deleteFormatSuccess,
  deleteFormatFailed,
} from "../actions/productsActions";

export function loadingProducts(search) {
  return (dispatch) => {
    dispatch(loadingProductsRequest());
    API.get(URLS.PRODUCTS.LIST(search))
      .then((response) => {
        dispatch(loadingProductsSuccess(response.data, true));
        // history.push('/dashboard');
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          error.response.data.detail ??
            "Loading transaction error !! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(loadingProductsFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function createProduct(data, history, isNormalUser) {
  return (dispatch) => {
    dispatch(createProductRequest());
    API.post(URLS.PRODUCTS.CREATE, data)
      .then((response) => {
        toast.success("New Product successfully added");
        const { data } = response;
        dispatch(createProductSuccess({ ...data, formats: [] }));
        history.push(isNormalUser ? "/products" : "/admin/products");
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ??
            error.response.data.error ??
            " Product creation error!! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(createProductFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function editProduct(id, data, history) {
  return (dispatch) => {
    dispatch(editProductRequest());
    API.patch(URLS.PRODUCTS.EDIT(id), data)
      .then((response) => {
        dispatch(editProductSuccess(id, response.data));
        toast.success("PRoduct edition success.");
        history.push("/admin/products");
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ?? "Product edit failed!! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(editProductFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

// Delete product effect
export function deleteProduct(id, history) {
  return (dispatch) => {
    dispatch(deleteProductRequest());
    const idToast = toast.loading("Please wait...");
    API.delete(URLS.PRODUCTS.DELETE(id))
      .then(() => {
        toast.update(idToast, {
          render: "Product deleted successfully",
          type: "success",
          isLoading: false,
        });
        dispatch(deleteProductSuccess(id));
        history.push("/admin/products");
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.update(idToast, {
          render:
            error.response.data.detail ??
            "Product deletion failed !! Please try later",
          type: "error",
          isLoading: false,
          autoClose: true,
        });
        // const errorMessage = formatError(error.response.data);
        dispatch(deleteProductFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

// Load product effect
export function loadProduct(id) {
  return (dispatch) => {
    dispatch(loadProductRequest());
    API.get(URLS.PRODUCTS.GET(id))
      .then((response) => {
        dispatch(loadProductSuccess(response.data));
        // history.push('/dashboard');
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ??
            "Loading product error!! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(loadProductFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

// Formats effects

export function loadingFormats(search) {
  return (dispatch) => {
    dispatch(loadingFormatsRequest());
    API.get(URLS.PRODUCTS.FORMATS.LIST(search))
      .then((response) => {
        dispatch(loadingFormatsSuccess(response.data, true));
        // history.push('/dashboard');
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          error.response.data.detail ??
            "Loading Formats error !! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(loadingFormatsFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function createFormat(data, history) {
  return (dispatch) => {
    dispatch(createFormatRequest());
    API.post(URLS.PRODUCTS.FORMATS.CREATE, data)
      .then((response) => {
        toast.success("New Format successfully added");
        const { data } = response;
        dispatch(createFormatSuccess(data));
        history.push("/admin/products");
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ??
            error.response.data.error ??
            " Format creation error!! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(createFormatFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function editFormat(id, data) {
  return (dispatch) => {
    dispatch(editFormatRequest());
    API.patch(URLS.PRODUCTS.FORMATS.EDIT(id), data)
      .then((response) => {
        toast.success("Format successfully edited");
        const { data } = response;
        dispatch(editFormatSuccess(data));
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          error.response.data.detail ??
            error.response.data.error ??
            " Format edition error!! Please try later"
        );
        // const errorMessage = formatError(error.response.data);
        dispatch(createFormatFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function deleteFormat(id) {
  return (dispatch) => {
    const idToast = toast.loading("Deleting, please wait...");
    dispatch(deleteFormatRequest());
    API.delete(URLS.PRODUCTS.FORMATS.DELETE(id))
      .then(() => {
        toast.update(idToast, {
          render: "Format successfully deleted",
          type: "success",
          isLoading: false,
        });
        dispatch(deleteFormatSuccess(id));
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.update(idToast, {
          render:
            error.response.data.detail ??
            "Format deletion failed !! Please try later",
          type: "error",
          isLoading: false,
          autoClose: true,
        });
        // const errorMessage = formatError(error.response.data);
        dispatch(deleteFormatFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}
