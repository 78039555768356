export const URLS = {
  BASE_URL: "https://api.cadyst.ova-consulting.fr/",
  BASE_URL_MEDIA: "https://api.cadyst.ova-consulting.fr",
  CLIENT_ID: "rgFz0scCkvH2qAm4iBWwk5m8f6qC9SVM5RHzrKze",
  AUTHS: {
    LOGIN: "auth/login/",
    REFRESH: "auth/token/refresh/",
    LOGOUT: "auth/logout/",
    REGISTRATION: "accounts/registration/",
    VERIFY_EMAIL: "/accounts/email/verification/",
  },

  USERS: {
    LIST: (search) => `users/?search=${search}`,
    // ME: "users/me/",
    USER: {
      ME: "users/me/",
      EDIT: (id) => `/users/user/${id}/`,
      LIST: (search) => `/users/user/?search=${search}`,
    },
    MY_TRANSACTIONS: "users/transactions/",
    SALERS: (search) => `users/salers/?search=${search}`,
    CLIENTS: {
      LIST: (search, page) =>
        `users/client/?search=${search}&page=${page ?? 1}`,
      CREATE: "users/client/",
      EDIT: (id) => `users/clients/${id}/`,
      DELETE: (id) => `users/clients/${id}/`,
      GET: (id) => `users/clients/${id}/`,
      ADDRESSES: {
        LIST: (search) => `users/address/?search=${search}`,
        CREATE: "users/address/",
        EDIT: (id) => `users/address/${id}/`,
        DELETE: (id) => `users/address/${id}/`,
        GET: (id) => `users/address/${id}/`,
      },
    },
    SALER: {
      LIST: (search) => `users/salers/?search=${search}`,
      CREATE: "accounts/saler/registration/",
      EDIT: (id) => `users/salers/${id}/`,
      DELETE: (id) => `users/salers/${id}/`,
      GET: (id) => `users/salers/${id}/`,
    },
  },
  PRODUCTS: {
    LIST: (search) => `products/products/?search=${search}`,
    CREATE: "products/products/",
    EDIT: (id) => `products/products/${id}/`,
    DELETE: (id) => `products/products/${id}/`,
    GET: (id) => `products/products/${id}/`,
    FORMATS: {
      LIST: (search) => `products/formats/?search=${search}`,
      CREATE: "products/formats/",
      EDIT: (id) => `products/formats/${id}/`,
      DELETE: (id) => `products/formats/${id}/`,
    },
  },
  PAYMENTS_METHODS: {
    LIST: "payment/methods/",
    CREATE: "payment/methods/",
    EDIT: (id) => `payment/methods/${id}/`,
    DELETE: (id) => `payment/methods/${id}/`,
    CLOSE: (id) => `payment/methods/${id}/close/`,
    GET: (id) => `payment/methods/${id}/`,
  },
  PRICE_LIST: {
    LIST: "payment/pricelist/",
    CREATE: "payment/pricelist/",
    EDIT: (id) => `payment/pricelist/${id}/`,
    DELETE: (id) => `payment/pricelist/${id}/`,
    GET: (id) => `payment/pricelist/${id}/`,
  },
  VISITS: {
    LIST: (search) => `visits/visits/?search=${search}`,
    CREATE: "visits/visits/",
    EDIT: (id) => `visits/visits/${id}/`,
    DELETE: (id) => `visits/visits/${id}/`,
    GET: (id) => `visits/visits/${id}/`,
    GETALL: "visits/visits/",
    INVENTORIES: "visits/inventories/",
  },
  ORDERS: {
    LIST: (search) => `orders/orders/?search=${search}`,
    CREATE: "orders/orders/",
    EDIT: (id) => `orders/orders/${id}/`,
    DELETE: (id) => `orders/orders/${id}/`,
    GET: (id) => `orders/orders/${id}/`,
  },
};
