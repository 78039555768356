import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import SecureLS from "secure-ls";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { loginConfirmedAction } from "store/actions/AuthActions";
import { changeLanguage } from "store/actions/InternationalizationActions";
import { setTranslationFunction } from "store/actions/InternationalizationActions";

const ls = new SecureLS({ encodingType: "aes" });
export default function Auth(props) {
  const { children } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    const user = ls.get("user-details");

    if (user.access_token && user.user) {
      API.get(URLS.USERS.ME)
        .then((resp) => {
          dispatch(loginConfirmedAction({ ...user, ...resp.data }));
        })
        .catch(() => {
          console.log("user details", user);
          dispatch(loginConfirmedAction(user));
        });
    }
  }, [dispatch, t]);

  React.useEffect(() => {
    const language = ls.get("lang");
    const navigatorLanguage = window.navigator.language;

    dispatch(setTranslationFunction(t));
    dispatch(changeLanguage(language ?? navigatorLanguage));
    i18n.changeLanguage(language ?? navigatorLanguage);
  }, []);
  return children;
}
