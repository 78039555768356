import {
  LOADING_PRODUCTS_FAILED,
  LOADING_PRODUCTS_REQUEST,
  LOADING_PRODUCTS_SUCCESS,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILED,
  DELETE_PRODUCT_FAILED,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_REQUEST,
  EDIT_PRODUCT_FAILED,
  EDIT_PRODUCT_REQUEST,
  EDIT_PRODUCT_SUCCESS,
  LOAD_PRODUCT_FAILED,
  LOAD_PRODUCT_REQUEST,
  LOAD_PRODUCT_SUCCESS,
  LOADING_FORMATS_FAILED,
  LOADING_FORMATS_REQUEST,
  LOADING_FORMATS_SUCCESS,
  CREATE_FORMAT_REQUEST,
  CREATE_FORMAT_SUCCESS,
  CREATE_FORMAT_FAILED,
  DELETE_FORMAT_FAILED,
  DELETE_FORMAT_SUCCESS,
  DELETE_FORMAT_REQUEST,
  EDIT_FORMAT_FAILED,
  EDIT_FORMAT_REQUEST,
  EDIT_FORMAT_SUCCESS,
  LOAD_FORMAT_FAILED,
  LOAD_FORMAT_REQUEST,
  LOAD_FORMAT_SUCCESS,
} from "../actions/productsActions";

const initialState = {
  loading_products_request: false,
  loading_products_success: false,
  loading_products_failed: false,
  loading_product_request: false,
  loading_product_success: false,
  loading_product_failed: false,
  create_product_request: false,
  create_product_success: false,
  create_product_failed: false,
  edit_product_request: false,
  edit_product_success: false,
  edit_product_failed: false,
  delete_product_request: false,
  delete_product_success: false,
  delete_product_failed: false,
  // Formats
  loading_formats_request: false,
  loading_formats_success: false,
  loading_formats_failed: false,
  loading_format_request: false,
  loading_format_success: false,
  loading_format_failed: false,
  create_format_request: false,
  create_format_success: false,
  create_format_failed: false,
  productsList: {
    count: 0,
    pages: 1,
    actualPage: 1,
    results: [],
  },
  formatsList: {
    count: 0,
    pages: 1,
    actualPage: 1,
    results: [],
  },
  product: undefined,
};

export function ProductsReducers(state = initialState, action) {
  switch (action.type) {
    case LOADING_PRODUCTS_REQUEST:
      return {
        ...state,
        loading_products_request: true,
        loading_products_success: false,
        loading_products_failed: false,
      };
    case LOADING_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading_products_request: false,
        loading_products_success: true,
        loading_products_failed: false,
        productsList: { ...state.productsList, ...action.payload.data },
      };
    case LOADING_PRODUCTS_FAILED:
      return {
        ...state,
        loading_products_request: false,
        loading_products_success: false,
        loading_products_failed: true,
      };

    case LOAD_PRODUCT_REQUEST:
      return {
        ...state,
        loading_product_request: true,
        loading_product_success: false,
        loading_product_failed: false,
      };
    case LOAD_PRODUCT_SUCCESS:
      return {
        ...state,
        loading_product_request: false,
        loading_product_success: true,
        loading_product_failed: false,
        product: action.payload,
      };
    case LOAD_PRODUCT_FAILED:
      return {
        ...state,
        loading_product_request: false,
        loading_product_success: false,
        loading_product_failed: true,
      };
    case CREATE_PRODUCT_REQUEST:
      return {
        ...state,
        create_product_request: true,
        create_product_success: false,
        create_product_failed: false,
      };
    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        create_product_request: false,
        create_product_success: true,
        create_product_failed: false,
        productsList: {
          ...state.productsList,
          results: [action.payload, ...state.productsList.results],
        },
      };
    case CREATE_PRODUCT_FAILED:
      return {
        ...state,
        create_product_request: false,
        create_product_success: false,
        create_product_failed: true,
      };

    case EDIT_PRODUCT_REQUEST:
      return {
        ...state,
        edit_product_request: true,
        edit_product_success: false,
        edit_product_failed: false,
      };
    case EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        edit_product_request: false,
        edit_product_success: true,
        edit_product_failed: false,
        productsList: {
          ...state.productsList,
          results: state.productsList.results.map((item) => {
            if (item.id === action.payload.id) {
              console.log("item.id === action.id");
              const {
                reference,
                name,
                description,
                image,
              } = action.payload.data;

              item.reference = reference;
              item.name = name;
              item.description = description;
              item.image = image;
            }
            return item;
          }),
        },
      };
    case EDIT_PRODUCT_FAILED:
      return {
        ...state,
        edit_product_request: false,
        edit_product_success: false,
        edit_product_failed: true,
      };

    case DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        delete_product_request: true,
        delete_product_success: false,
        delete_product_failed: false,
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        delete_product_request: false,
        delete_product_success: true,
        delete_product_failed: false,
        productsList: {
          ...state.productsList,
          results: state.productsList.results.filter(
            (item) => item.id != action.payload.id
          ),
        },
      };
    case DELETE_PRODUCT_FAILED:
      return {
        ...state,
        create_product_request: false,
        create_product_success: false,
        create_product_failed: true,
      };
    // Formats reducers
    case LOADING_FORMATS_REQUEST:
      return {
        ...state,
        loading_formats_request: true,
        loading_formats_success: false,
        loading_formats_failed: false,
      };
    case LOADING_FORMATS_SUCCESS:
      return {
        ...state,
        loading_formats_request: false,
        loading_formats_success: true,
        loading_formats_failed: false,
        formatsList: action.payload.isAdmin
          ? { ...state.formatsList, ...action.payload.data }
          : { ...state.formatsList, ...action.payload.data },
      };
    case LOADING_FORMATS_FAILED:
      return {
        ...state,
        loading_formats_request: false,
        loading_formats_success: false,
        loading_formats_failed: true,
      };
    case LOAD_FORMAT_REQUEST:
      return {
        ...state,
        loading_format_request: true,
        loading_format_success: false,
        loading_format_failed: false,
      };
    case LOAD_FORMAT_SUCCESS:
      return {
        ...state,
        loading_format_request: false,
        loading_format_success: true,
        loading_format_failed: false,
        format: action.payload,
      };
    case LOAD_FORMAT_FAILED:
      return {
        ...state,
        loading_format_request: false,
        loading_format_success: false,
        loading_format_failed: true,
      };
    case CREATE_FORMAT_REQUEST:
      return {
        ...state,
        create_format_request: true,
        create_format_success: false,
        create_format_failed: false,
      };
    case CREATE_FORMAT_SUCCESS:
      return {
        ...state,
        create_format_request: false,
        create_format_success: true,
        create_format_failed: false,
        formatsList: {
          ...state.formatsList,
          results: [action.payload, ...state.formatsList.results],
        },
      };
    case CREATE_FORMAT_FAILED:
      return {
        ...state,
        create_format_request: false,
        create_format_success: false,
        create_format_failed: true,
      };

    case EDIT_FORMAT_REQUEST:
      return {
        ...state,
        edit_format_request: true,
        edit_format_success: false,
        edit_format_failed: false,
      };
    case EDIT_FORMAT_SUCCESS:
      return {
        ...state,
        edit_format_request: false,
        edit_format_success: true,
        edit_format_failed: false,
        formatsList: {
          ...state.formatsList,
          results: state.formatsList.results.map((item) => {
            item.formats = item.formats.map((format) => {
              if (format.id === action.payload.id) {
                format = { ...action.payload.data, ...action.payload.data };
              }
            });
            return item;
          }),
        },
      };
    case EDIT_FORMAT_FAILED:
      return {
        ...state,
        edit_format_request: false,
        edit_format_success: false,
        edit_format_failed: true,
      };

    case DELETE_FORMAT_REQUEST:
      return {
        ...state,
        delete_format_request: true,
        delete_format_success: false,
        delete_format_failed: false,
      };
    case DELETE_FORMAT_SUCCESS:
      return {
        ...state,
        delete_format_request: false,
        delete_format_success: true,
        delete_format_failed: false,
        formatsList: {
          ...state.formatsList,
          results: state.formatsList.results.filter(
            (item) => item.id != action.payload.id
          ),
        },
      };
    case DELETE_FORMAT_FAILED:
      return {
        ...state,
        create_format_request: false,
        create_format_success: false,
        create_format_failed: true,
      };
    default:
      return state;
  }
}
