/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import RTL from "layouts/RTL.js";
import { Provider } from "react-redux";
import { store } from "./store/store";

import "assets/css/material-dashboard-react.css?v=1.10.0";
import Login from "views/login/LoginPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Auth from "layouts/Auth";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "components/Theme/Theme";
// import { ChakraProvider } from "@chakra-ui/react";
import "./translations";

ReactDOM.render(
  <Provider store={store}>
    {/* <ChakraProvider> */}
    <ThemeProvider theme={theme}>
      <Auth>
        <BrowserRouter>
          <ToastContainer />
          <Switch>
            <Route path="/admin" component={Admin} />
            <Route path="/rtl" component={RTL} />
            <Route path={"/login"} component={Login} />
            <Redirect from="/" to="/admin/dashboard" />
          </Switch>
        </BrowserRouter>
      </Auth>
    </ThemeProvider>
    {/* </ChakraProvider> */}
  </Provider>,
  document.getElementById("root")
);
