import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
import { PaymentsReducers } from "./reducers/PaymentsMethodsReducers";
import { ClientsReducers } from "./reducers/ClientsReducers";
import { ProductsReducers } from "./reducers/productsReducers";
import { PricesListReducers } from "./reducers/PricesListReducers";
import { UsersReducers } from "./reducers/UsersReducers";
import { visitsReducers } from "./reducers/VisitsReducers";
import {
  editLoggedUserReducers,
  loggedUserReducers,
} from "./reducers/loggedUser";
import { InternationalisationReducers } from "./reducers/InternationalisationReducers";
import {
  editSalerProfileReducers,
  SalerProfileReducers,
} from "./reducers/salerProfileReducer";
import { updatePasswordReducers } from "./reducers/updateAccountPassReducer";
import { usersListReducers } from "./reducers/usersListReducers";

const middleware = applyMiddleware(thunk);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  auth: AuthReducer,
  users: UsersReducers,
  loggedUser: loggedUserReducers,
  editedUser: editLoggedUserReducers,
  products: ProductsReducers,
  payments: PaymentsReducers,
  pricesList: PricesListReducers,
  clients: ClientsReducers,
  visitsList: visitsReducers,
  i18n: InternationalisationReducers,
  salerprofile: SalerProfileReducers,
  editsaler: editSalerProfileReducers,
  updadePassword: updatePasswordReducers,
  usersList: usersListReducers,
});

export const store = createStore(reducers, composeEnhancers(middleware));
