/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
// import Person from "@material-ui/icons/Person";
// import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import BubbleChart from "@material-ui/icons/BubbleChart";
// import LocationOn from "@material-ui/icons/LocationOn";
// import Notifications from "@material-ui/icons/Notifications";
// import Unarchive from "@material-ui/icons/Unarchive";
// import Language from "@material-ui/icons/Language";
import ListAltIcon from "@material-ui/icons/ListAlt";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
// import UserProfile from "views/UserProfile/UserProfile.js";
// import TableList from "views/TableList/TableList.js";
// import Typography from "views/Typography/Typography.js";
// import Icons from "views/Icons/Icons.js";
// import Maps from "views/Maps/Maps.js";
// import NotificationsPage from "views/Notifications/Notifications.js";
// import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// core components/views for RTL layout
// import RTLPage from "views/RTLPage/RTLPage.js";
import ProductsList from "views/Products/Products";
import NewProduct from "views/Products/NewProduct";
import NewFormat from "views/Products/NewFormat";
import NewPaymentMethods from "views/Payments/NewPaymentMethod";
import ClientsList from "views/clients/Clients";
import PeopleOutlinedIcon from "@material-ui/icons/PeopleOutlined";
import ReorderIcon from "@material-ui/icons/Reorder";
import UserProfile from "views/UserProfile/UserProfile";
import { Person, VisibilityOutlined } from "@material-ui/icons";
import Visits from "views/Visits/Visits";
import UsersList from "views/UsersList/UsersList";
// import { useTranslation } from "react-i18next";

// const { t } = useTranslation();
const dashboardRoutes = (t) => [
  {
    path: "/dashboard",
    name: t("Dashboard"),
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/orders",
    name: t("Orders"),
    rtlName: "لوحة القيادة",
    icon: ReorderIcon,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/products",
    name: t("Products"),
    rtlName: "لوحة القيادة",
    icon: ListAltIcon,
    component: ProductsList,
    layout: "/admin",
    children: [
      {
        path: "/products",
        name: t("Products_list"),
        rtlName: "لوحة القيادة",
        icon: ListAltIcon,
        component: ProductsList,
        layout: "/admin",
      },
      {
        path: "/products/new",
        name: t("New_Product"),
        rtlName: "لوحة القيادة",
        icon: ListAltIcon,
        component: NewProduct,
        layout: "/admin",
      },
      {
        path: "/products/formats/new",
        name: t("New_Format"),
        rtlName: "لوحة القيادة",
        icon: ListAltIcon,
        component: NewFormat,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/payments",
    name: t("Payments"),
    rtlName: "لوحة القيادة",
    icon: ListAltIcon,
    component: ProductsList,
    layout: "/admin",
    children: [
      {
        path: "/payments/methods",
        name: t("Payment_methods"),
        rtlName: "لوحة القيادة",
        icon: ListAltIcon,
        component: ProductsList,
        layout: "/admin",
      },
      {
        path: "/payments/prices-list",
        name: t("Prices_list"),
        rtlName: "لوحة القيادة",
        icon: ListAltIcon,
        component: NewFormat,
        layout: "/admin",
      },
      {
        path: "/payments/prices-list/new",
        name: t("New_Prices_List"),
        rtlName: "لوحة القيادة",
        icon: ListAltIcon,
        component: NewFormat,
        layout: "/admin",
      },
      {
        path: "/payments/methods/new",
        name: t("New_payment_method"),
        rtlName: "لوحة القيادة",
        icon: ListAltIcon,
        component: NewPaymentMethods,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/clients",
    name: "Clients",
    rtlName: "لوحة القيادة",
    icon: PeopleOutlinedIcon,
    component: ClientsList,
    layout: "/admin",
    children: [
      {
        path: "/clients",
        name: t("Clients_list"),
        rtlName: "لوحة القيادة",
        icon: PeopleOutlinedIcon,
        component: ClientsList,
        layout: "/admin",
      },
      {
        path: "/clients/addresses",
        name: t("Clients_Addresses"),
        rtlName: "لوحة القيادة",
        icon: ListAltIcon,
        component: ProductsList,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/salers",
    name: t("Salers"),
    rtlName: "لوحة القيادة",
    icon: PeopleOutlinedIcon,
    component: ClientsList,
    layout: "/admin",
    children: [
      {
        path: "/salers",
        name: "Salers_list",
        rtlName: "لوحة القيادة",
        icon: PeopleOutlinedIcon,
        component: ClientsList,
        layout: "/admin",
      },
      // {
      //   path: "/salers/visits",
      //   name: "Salers visits",
      //   rtlName: "لوحة القيادة",
      //   icon: ListAltIcon,
      //   component: ProductsList,
      //   layout: "/admin",
      // },
    ],
  },
  {
    path: "/user",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "/visits",
    name: t("Visits"),
    rtlName: "الزيارات",
    icon: VisibilityOutlined,
    component: Visits,
    layout: "/admin",
  },
  {
    path: "/userslist",
    name: t("Users_List"),
    rtlName: "",
    icon: ListAltIcon,
    component: UsersList,
    layout: "/admin",
  },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   rtlName: "قائمة الجدول",
  //   icon: "content_paste",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   rtlName: "طباعة",
  //   icon: LibraryBooks,
  //   component: Typography,
  //   layout: "/admin",
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   rtlName: "الرموز",
  //   icon: BubbleChart,
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   rtlName: "خرائط",
  //   icon: LocationOn,
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   rtlName: "إخطارات",
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: "/admin",
  // },
  // {
  //   path: "/rtl-page",
  //   name: "RTL Support",
  //   rtlName: "پشتیبانی از راست به چپ",
  //   icon: Language,
  //   component: RTLPage,
  //   layout: "/rtl",
  // },
  // {
  //   path: "/upgrade-to-pro",
  //   name: "Upgrade To PRO",
  //   rtlName: "التطور للاحترافية",
  //   icon: Unarchive,
  //   component: UpgradeToPro,
  //   layout: "/admin",
  // },
];

export default dashboardRoutes;
