import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {
  Box,
  LinearProgress,
  IconButton,
  TextField,
  Collapse,
  Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { loadingProducts } from "store/effects/productsEffect";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
// core components
import tableStyles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { withStyles } from "@material-ui/styles";

import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useTranslation } from "react-i18next";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  mlAuto: {
    marginLeft: "auto",
  },
  dFlex: {
    display: "flex",
  },
  table: {
    minWidth: 650,
  },
  textSearch: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
  },
};
const SearchTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: "white",
      borderWidth: 2,
    },
    background: '#fff',
    borderRadius: '4px',
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
    "& fieldset": {
      borderColor: "#ffffff",
    },
    "& input": {
      color: "#ffffff",
    },
  },
})(TextField);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  return (
    <React.Fragment>
      <StyledTableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.reference}
        </TableCell>
        <TableCell>{row.priceBT}</TableCell>
        <TableCell>{row.priceAT}</TableCell>
        <TableCell>{"XAF"}</TableCell>
        <TableCell>
          {row.client?.first_name + " " + row.client?.last_name}
        </TableCell>
        <TableCell>{row.payment_method.label}</TableCell>
        <TableCell>{row.description}</TableCell>
        <TableCell>{row.order_date}</TableCell>
        <TableCell>{row.delivery_date}</TableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Order lines
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>{props.trans("Quantity")}</TableCell>
                    <TableCell>{props.trans("Unit_price")}</TableCell>
                    <TableCell>{props.trans("Total_price")}</TableCell>
                    <TableCell>Format</TableCell>
                    <TableCell>{props.trans("Created_at")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.order_lines?.map((oderLine) => (
                    <TableRow key={oderLine.id}>
                      <TableCell component="th" scope="row">
                        {oderLine.quantity}
                      </TableCell>
                      <TableCell>{oderLine.unit_price}</TableCell>
                      <TableCell>{oderLine.total_price}</TableCell>
                      <TableCell>{oderLine.format.label}</TableCell>
                      <TableCell>{oderLine.created_at}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const useStyles = makeStyles(styles);
const useStyles1 = makeStyles(tableStyles);

export default function OrdersList() {
  const classes = useStyles();
  const { t } = useTranslation();
  const classes1 = useStyles1();
  const dispatch = useDispatch();
  const [ordersList, setOrdersList] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  // const [query, setQuery] = useState("");

  const handleSearch = (event) => {
    // let val = event.target.value;
    // setQuery(val);
    // console.log(
    //   ordersList.results.filter((order) =>
    //     order.reference.include("BCSFAPST2022")
    //   )
    // );

    dispatch(loadingProducts(event.target.value ?? ""));
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    API.get(URLS.ORDERS.LIST("", page))
      .then((resp) => {
        const { data } = resp;
        setOrdersList(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [page]);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <Box className={classes.dFlex}>
              <div>
                <h4 className={classes.cardTitleWhite}>{t("Orders_list")}</h4>
                <p className={classes.cardCategoryWhite}>
                  {t("The_list_of_orders_according_to_your_branch")}
                </p>
              </div>
              <div className={classes.mlAuto}>
                <SearchTextField
                  us
                  keep
                  running
                  id="outlined-basic"
                  label={t("Search")}
                  variant="outlined"
                  onChange={handleSearch}
                  style={{ marginRight: "1rem", marginTop: "0px" }}
                  margin="dense"
                />
              </div>
            </Box>
          </CardHeader>
          <CardBody>
            <TableContainer>
              <Table className={classes1.table} aria-label="simple table">
                <TableHead className={classes1["primaryTableHeader"]}>
                  <TableRow className={classes1.tableHeadRow}>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    />
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      Reference
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {t("Price_before_tax")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {t("Price_after_tax")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      {t("Currency")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      Client
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {t("Payment_method")}
                    </TableCell>

                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      Description
                    </TableCell>

                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      {t("Created_at")}
                    </TableCell>

                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      {t("Edited_at")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isLoading &&
                    ordersList &&
                    ordersList?.results.map((order) => {
                      return <Row key={order.id} row={order} trans={t} />;
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {ordersList?.results.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[25]}
                component="div"
                count={ordersList?.count}
                rowsPerPage={25}
                page={ordersList?.page - 1}
                onPageChange={handleChangePage}
              />
            )}
            {isLoading && <LinearProgress />}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
