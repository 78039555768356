import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes" });
function AdminGuard(props) {
  const { children } = props;
  const account = useSelector((state) => state.auth.auth);

  console.log("maindf =============>", account);
  const user = ls.get("user-details");
  if (!user) {
    return <Redirect to={"/login"} />;
  }

  return children;
}

export default AdminGuard;
