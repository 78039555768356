import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {
  Button,
  Box,
  LinearProgress,
  IconButton,
  TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useTranslation } from "react-i18next";
// core components
import tableStyles from "assets/jss/material-dashboard-react/components/tableStyle.js";
// import { MoreHoriz } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import { loadingPaymentMethods } from "store/effects/paymentEffects";
// import { useTheme } from '@mui/material/styles';
import DoneAllIcon from "@material-ui/icons/DoneAll";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Tooltip from "@material-ui/core/Tooltip";
import Swal from "sweetalert2";
import { deletePayment } from "store/effects/paymentEffects";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  mlAuto: {
    marginLeft: "auto",
  },
  dFlex: {
    display: "flex",
  },
  table: {
    minWidth: 650,
  },
  tableTextError: {
    color: "red",
  },
  tableTextSuccess: {
    color: "red",
  },
  textSearch: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
  },
};
const SearchTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: "white",
      borderWidth: 2,
    },
    background: '#fff',
    borderRadius: '4px',
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
    "& fieldset": {
      borderColor: "#ffffff",
    },
    "& input": {
      color: "#ffffff",
    },
  },
})(TextField);

const useStyles = makeStyles(styles);
const useStyles1 = makeStyles(tableStyles);

export default function PaymentsMethodsList() {
  const classes = useStyles();
  const classes1 = useStyles1();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //   const theme = useTheme();
  const { paymentsList, loading_payments_request } = useSelector(
    (state) => state.payments
  );
  const handleSearch = (event) => {
    dispatch(loadingPaymentMethods(event.target.value ?? ""));
  };

  const handleDeletePaymentMethods = (id) => {
    Swal.fire({
      title: t("Are_you_sure"),
      text: t("delete_message_warning"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Delete_validation"),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deletePayment(id));
      }
    });
  };
  useEffect(() => {
    dispatch(loadingPaymentMethods(""));
  }, []);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Box sx={{display: 'flex'}}>
          <Box sx={{marginLeft: ''}} mb={2}>
            <Button
                  variant={"contained"}
                  color={"primary"}
                  startIcon={<AddIcon />}
                  component={Link}
                  to={"/admin/payments/methods/new"}
                >
                  {t("New_Payment_Method")}
                </Button>
          </Box>
        </Box>
        <Card>
          <CardHeader color="primary">
            <Box className={classes.dFlex}>
              <div>
                <h4 className={classes.cardTitleWhite}>{t("Payments_list")}</h4>
                <p className={classes.cardCategoryWhite}>
                  {t("The_list_of_payments_according_to_your_branch")}
                </p>
              </div>
              <div className={classes.mlAuto}>
                <SearchTextField
                  id="outlined-basic"
                  label={t("Search")}
                  variant="outlined"
                  onChange={handleSearch}
                  style={{ marginRight: "1rem", marginTop: "0px" }}
                  margin="dense"
                />
                
              </div>
            </Box>
          </CardHeader>
          <CardBody>
            <TableContainer>
              <Table className={classes1.table} aria-label="simple table">
                <TableHead className={classes1["primaryTableHeader"]}>
                  <TableRow className={classes1.tableHeadRow}>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      {t("Payment_name")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      {t("Status")}
                    </TableCell>
                    <TableCell
                      className={
                        classes1.tableCell + " " + classes1.tableHeadCell
                      }
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentsList.results.map((paymentMethod) => {
                    return (
                      <TableRow
                        key={paymentMethod.id}
                        className={classes1.tableBodyRow}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes1.tableCell}
                        >
                          {paymentMethod.label}
                        </TableCell>
                        <TableCell className={classes1.tableCell}>
                          {paymentMethod.is_active ? (
                            <span style={{ color: "green" }}>Active</span>
                          ) : (
                            <span style={{ color: "red" }}>Disabled</span>
                          )}
                        </TableCell>
                        <TableCell className={classes1.tableCell}>
                          {!paymentMethod.is_active ? (
                            <Tooltip title="Activate" arrow>
                              <IconButton>
                                <DoneAllIcon color="success" />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Disable" arrow>
                              <IconButton>
                                <NotInterestedIcon color="error" />
                              </IconButton>
                            </Tooltip>
                          )}{" "}
                          <Tooltip title="Delete" arrow>
                            <IconButton
                              onClick={() =>
                                handleDeletePaymentMethods(paymentMethod.id)
                              }
                            >
                              <DeleteOutlineIcon color="error" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {loading_payments_request && <LinearProgress />}
          </CardBody>
        </Card>
      </GridItem>
      {/* <GridItem xs={12} sm={12} md={12}>
        <Card plain>
          <CardHeader plain color="primary">
            <h4 className={classes.cardTitleWhite}>
              Table on Plain Background
            </h4>
            <p className={classes.cardCategoryWhite}>
              Here is a subtitle for this table
            </p>
          </CardHeader>
          <CardBody></CardBody>
        </Card>
      </GridItem> */}
    </GridContainer>
  );
}
