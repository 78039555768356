import {
  LOADING_PAYMENTS_FAILED,
  LOADING_PAYMENTS_REQUEST,
  LOADING_PAYMENTS_SUCCESS,
  CREATE_PAYMENT_REQUEST,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_FAILED,
  DELETE_PAYMENT_FAILED,
  DELETE_PAYMENT_SUCCESS,
  DELETE_PAYMENT_REQUEST,
  EDIT_PAYMENT_FAILED,
  EDIT_PAYMENT_REQUEST,
  EDIT_PAYMENT_SUCCESS,
  LOAD_PAYMENT_FAILED,
  LOAD_PAYMENT_REQUEST,
  LOAD_PAYMENT_SUCCESS,
} from "../actions/PaymentsActions";

const initialState = {
  loading_payments_request: false,
  loading_payments_success: false,
  loading_payments_failed: false,
  loading_payment_request: false,
  loading_payment_success: false,
  loading_payment_failed: false,
  create_payment_request: false,
  create_payment_success: false,
  create_payment_failed: false,
  edit_payment_request: false,
  edit_payment_success: false,
  edit_payment_failed: false,
  delete_payment_request: false,
  delete_payment_success: false,
  delete_payment_failed: false,
  paymentsList: {
    count: 0,
    pages: 1,
    actualPage: 1,
    results: [],
  },
  payment: undefined,
};

export function PaymentsReducers(state = initialState, action) {
  switch (action.type) {
    case LOADING_PAYMENTS_REQUEST:
      return {
        ...state,
        loading_payments_request: true,
        loading_payments_success: false,
        loading_payments_failed: false,
      };
    case LOADING_PAYMENTS_SUCCESS:
      return {
        ...state,
        loading_payments_request: false,
        loading_payments_success: true,
        loading_payments_failed: false,
        paymentsList: action.payload.isAdmin
          ? { ...state.paymentsList, ...action.payload.data }
          : { ...state.paymentsList, ...action.payload.data },
      };
    case LOADING_PAYMENTS_FAILED:
      return {
        ...state,
        loading_payments_request: false,
        loading_payments_success: false,
        loading_payments_failed: true,
      };
    case LOAD_PAYMENT_REQUEST:
      return {
        ...state,
        loading_payment_request: true,
        loading_payment_success: false,
        loading_payment_failed: false,
      };
    case LOAD_PAYMENT_SUCCESS:
      return {
        ...state,
        loading_payment_request: false,
        loading_payment_success: true,
        loading_payment_failed: false,
        payment: action.payload,
      };
    case LOAD_PAYMENT_FAILED:
      return {
        ...state,
        loading_payment_request: false,
        loading_payment_success: false,
        loading_payment_failed: true,
      };
    case CREATE_PAYMENT_REQUEST:
      return {
        ...state,
        create_payment_request: true,
        create_payment_success: false,
        create_payment_failed: false,
      };
    case CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        create_payment_request: false,
        create_payment_success: true,
        create_payment_failed: false,
        // paymentsList: {
        //     ...state.paymentsList,
        //     results: [action.payload, ...state.paymentsList.results]
        // },
      };
    case CREATE_PAYMENT_FAILED:
      return {
        ...state,
        create_payment_request: false,
        create_payment_success: false,
        create_payment_failed: true,
      };

    case EDIT_PAYMENT_REQUEST:
      return {
        ...state,
        edit_payment_request: true,
        edit_payment_success: false,
        edit_payment_failed: false,
      };
    case EDIT_PAYMENT_SUCCESS:
      return {
        ...state,
        edit_payment_request: false,
        edit_payment_success: true,
        edit_payment_failed: false,
        paymentsList: {
          ...state.paymentsList,
          results: state.paymentsList.results.map((item) => {
            if (item.id === action.id) {
              item = action.payload.data;
            }

            return item;
          }),
        },
      };
    case EDIT_PAYMENT_FAILED:
      return {
        ...state,
        edit_payment_request: false,
        edit_payment_success: false,
        edit_payment_failed: true,
      };

    case DELETE_PAYMENT_REQUEST:
      return {
        ...state,
        delete_payment_request: true,
        delete_payment_success: false,
        delete_payment_failed: false,
      };
    case DELETE_PAYMENT_SUCCESS:
      return {
        ...state,
        delete_payment_request: false,
        delete_payment_success: true,
        delete_payment_failed: false,
        paymentsList: {
          ...state.paymentsList,
          results: state.paymentsList.results.filter(
            (item) => item.id != action.payload.id
          ),
        },
      };
    case DELETE_PAYMENT_FAILED:
      return {
        ...state,
        create_payment_request: false,
        create_payment_success: false,
        create_payment_failed: true,
      };

    default:
      return state;
  }
}
