// core components
// import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
// Formik form
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { loadingFormats } from "store/effects/productsEffect";
import { loadingClients } from "store/effects/clientsEffects";
import { createPricesList } from "store/effects/priceListsEffect";
import { useTranslation } from "react-i18next";

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
const validationSchema = (t) =>
  yup.object({
    description: yup
      .string("Enter the price's description")
      .required(t("The_prices_description_is_required")),
    price: yup
      .number("Enter a Format's valid price")
      .required(t("The_Formats_price_is_required")),
  });

export default function NewFormat(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    formatsList: { results },
    loading_products_request,
    create_format_request,
  } = useSelector((state) => state.products);
  const { clientList, loading_clients_request } = useSelector(
    (state) => state.clients
  );
  const [value, setValue] = useState();
  const [selectedClient, setSelectedClient] = useState();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      label: "",
      reference: "",
      description: "",
    },
    validationSchema: validationSchema(t),
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
      let formData = new FormData();
      formData.append("label", values.label);
      formData.append("description", values.description);
      formData.append("price", values.price);
      if (value) {
        formData.append("format", value.id);
      }
      if (selectedClient) {
        formData.append("client", selectedClient.id);
      }
      dispatch(createPricesList(formData, props.history));
    },
  });
  const handleSearchFormat = (value) => {
    dispatch(loadingFormats(value ?? ""));
  };
  const handleSearchClient = (value) => {
    dispatch(loadingClients(value ?? ""));
  };
  useEffect(() => {
    dispatch(loadingFormats(""));
    dispatch(loadingClients(""));
  }, []);
  return (
    <GridContainer>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}> {t("New_price_list")} </h4>
          <p className={classes.cardCategoryWhite}>
            {t("You_are_creating_a_price_list")}
          </p>
        </CardHeader>
        <CardBody>
          <Container>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <Autocomplete
                    value={selectedClient}
                    onChange={(event, newValue) => {
                      setSelectedClient(newValue);
                      console.log("newValue ========>", newValue);
                    }}
                    loading={loading_clients_request}
                    // inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      handleSearchClient(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={clientList.results}
                    // style={{ width: 300 }}
                    getOptionLabel={(option) =>
                      `${option.first_name + " " + option.last_name}`
                    }
                    renderOption={(option) => (
                      <React.Fragment>
                        {" "}
                        {option.first_name + " " + option.last_name}{" "}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Select_a_client")}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                      console.log("newValue ========>", newValue);
                    }}
                    loading={loading_products_request}
                    // inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      handleSearchFormat(newInputValue);
                    }}
                    id="controllable-stastes-demo"
                    options={results}
                    // style={{ width: 300 }}
                    getOptionLabel={(option) =>
                      `${option.product.name + " - " + option.label}`
                    }
                    renderOption={(option) => (
                      <React.Fragment key={option.id}>
                        {" "}
                        {option.product.name + " - " + option.label}{" "}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Select_a_format")}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="price"
                    type={"number"}
                    name="price"
                    label={t("Price") + " *"}
                    variant="outlined"
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    helperText={formik.touched.price && formik.errors.price}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="description"
                    name="description"
                    label="Description *"
                    variant="outlined"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                    multiline
                  />
                </Grid>
                <Grid item>
                  <Button
                    disabled={create_format_request}
                    type={"submit"}
                    variant="contained"
                    color="primary"
                  >
                    {t("save_the_price")}{" "}
                    {create_format_request && <CircularProgress size={14} />}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
