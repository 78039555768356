export const LOADING_PAYMENTS_REQUEST = "LOADING_PAYMENTS_REQUEST";
export const LOADING_PAYMENTS_SUCCESS = "LOADING_PAYMENTS_SUCCESS";
export const LOADING_PAYMENTS_FAILED = "LOADING_PAYMENTS_FAILED";

export const CREATE_PAYMENT_REQUEST = "CREATE_PAYMENT_REQUEST";
export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS";
export const CREATE_PAYMENT_FAILED = "CREATE_PAYMENT_FAILED";

export const EDIT_PAYMENT_REQUEST = "EDIT_PAYMENT_REQUEST";
export const EDIT_PAYMENT_SUCCESS = "EDIT_PAYMENT_SUCCESS";
export const EDIT_PAYMENT_FAILED = "EDIT_PAYMENT_FAILED";

export const DELETE_PAYMENT_REQUEST = "DELETE_PAYMENT_REQUEST";
export const DELETE_PAYMENT_SUCCESS = "DELETE_PAYMENT_SUCCESS";
export const DELETE_PAYMENT_FAILED = "DELETE_PAYMENT_FAILED";

export const LOAD_PAYMENT_REQUEST = "LOAD_PAYMENT_REQUEST";
export const LOAD_PAYMENT_SUCCESS = "LOAD_PAYMENT_SUCCESS";
export const LOAD_PAYMENT_FAILED = "LOAD_PAYMENT_FAILED";

export function loadingPaymentsRequest() {
  return {
    type: LOADING_PAYMENTS_REQUEST,
  };
}

export function loadingPaymentsSuccess(data, isAdmin) {
  return {
    type: LOADING_PAYMENTS_SUCCESS,
    payload: { data, isAdmin },
  };
}

export function loadingPaymentsFailed() {
  return {
    type: LOADING_PAYMENTS_FAILED,
  };
}

// Create payment action

export function createPaymentRequest() {
  return {
    type: CREATE_PAYMENT_REQUEST,
  };
}

export function createPaymentSuccess(data) {
  return {
    type: CREATE_PAYMENT_SUCCESS,
    payload: data,
  };
}

export function createPaymentFailed() {
  return {
    type: CREATE_PAYMENT_FAILED,
  };
}

// Edit payment actions

export function editPaymentRequest() {
  return {
    type: EDIT_PAYMENT_REQUEST,
  };
}

export function editPaymentSuccess(id, data) {
  return {
    type: EDIT_PAYMENT_SUCCESS,
    payload: { id, data },
  };
}

export function editPaymentFailed() {
  return {
    type: EDIT_PAYMENT_FAILED,
  };
}

// Delete Payment actions

export function deletePaymentRequest() {
  return {
    type: DELETE_PAYMENT_REQUEST,
  };
}

export function deletePaymentSuccess(id) {
  return {
    type: DELETE_PAYMENT_SUCCESS,
    payload: { id: id },
  };
}

export function deletePaymentFailed() {
  return {
    type: DELETE_PAYMENT_FAILED,
  };
}
// Load Payment actions

export function loadPaymentRequest() {
  return {
    type: LOAD_PAYMENT_REQUEST,
  };
}

export function loadPaymentSuccess(payment) {
  return {
    type: LOAD_PAYMENT_SUCCESS,
    payload: payment,
  };
}

export function loadPaymentFailed() {
  return {
    type: LOAD_PAYMENT_FAILED,
  };
}
