import { toast } from "react-toastify";
import { loadUsersFailed } from "store/actions/UsersListAction";
import { loadUsersSuccess } from "store/actions/UsersListAction";
import { loadUsersRequest } from "store/actions/UsersListAction";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls/urls";

export function loadingUsersList(search) {
  return (dispatch) => {
    dispatch(loadUsersRequest());
    API.get(URLS.USERS.USER.LIST(search))
      // API.get(`https://api.cadyst.ova-consulting.fr/admin/users/user/${search}/`)
      .then((response) => {
        dispatch(loadUsersSuccess(response.data, true));
        console.log("this is the response", response.data);
        // history.push("/dashboard");
      })
      .catch((error) => {
        // console.log(error);
        toast.error(
          error.response.data.detail ??
            "Loading transaction error !! Please try later"
        );

        dispatch(loadUsersFailed());
      });
  };
}
