import { LOAD_LOGGED_USER_REQUEST } from "store/actions/usersActions";
import { EDIT_LOGGED_USER_REQUEST } from "store/actions/usersActions";
import { EDIT_LOGGED_USER_FAILED } from "store/actions/usersActions";
import { EDIT_LOGGED_USER_SUCCESS } from "store/actions/usersActions";
import { LOAD_LOGGED_USER } from "store/actions/usersActions";
import { LOAD_LOGGED_USER_FAILED } from "store/actions/usersActions";

const initialState = {
  loading_user_request: false,
  loading_user_success: false,
  loading_user_failed: false,
  user: undefined,
};

const initialState2 = {
  edit_logged_user_request: false,
  edit_logged_user_success: false,
  edit_logged_user_failed: false,
  user2: undefined,
};

export function loggedUserReducers(state = initialState, action) {
  switch (action.type) {
    case LOAD_LOGGED_USER_REQUEST:
      return {
        ...state,
        loading_user_request: true,
        loading_user_success: false,
        loading_user_failed: false,
      };
    case LOAD_LOGGED_USER:
      return {
        ...state,
        loading_user_request: false,
        loading_user_success: true,
        loading_user_failed: false,
        user: action.payload,
      };
    case LOAD_LOGGED_USER_FAILED:
      return {
        ...state,
        loading_user_request: false,
        loading_user_success: false,
        loading_user_failed: true,
      };
    default:
      return state;
  }
}

export function editLoggedUserReducers(state = initialState2, action) {
  switch (action.type) {
    case EDIT_LOGGED_USER_REQUEST:
      return {
        ...state,
        edit_logged_user_request: true,
        edit_logged_user_success: false,
        edit_logged_user_failed: false,
      };
    case EDIT_LOGGED_USER_SUCCESS:
      return {
        ...state,
        edit_logged_user_request: false,
        edit_logged_user_success: true,
        edit_logged_user_failed: false,
        user2: action.payload,
      };
    case EDIT_LOGGED_USER_FAILED:
      return {
        ...state,
        edit_logged_user_request: false,
        edit_logged_user_success: false,
        edit_logged_user_failed: true,
      };
    default:
      return state;
  }
}
