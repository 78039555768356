import { LOAD_USERSLIST_FAILED } from "store/actions/UsersListAction";
import { LOAD_USERSLIST_SUCCESS } from "store/actions/UsersListAction";
import { LOAD_USERSLIST_REQUEST } from "store/actions/UsersListAction";

const initialState = {
  loading_users_request: false,
  loading_users_success: false,
  loading_users_failed: false,
  usersList: {
    next: null,
    previous: null,
    count: 0,
    pages: 1,
    page: 1,
    results: [],
  },
};

export function usersListReducers(state = initialState, action) {
  switch (action.type) {
    case LOAD_USERSLIST_REQUEST:
      return {
        ...state,
        loading_users_request: true,
        loading_users_success: false,
        loading_users_failed: false,
      };
    case LOAD_USERSLIST_SUCCESS:
      return {
        ...state,
        loading_users_request: false,
        loading_users_success: true,
        loading_users_failed: false,
        usersList: { ...state.usersList, ...action.payload.data },
      };
    case LOAD_USERSLIST_FAILED:
      return {
        ...state,
        loading_users_request: false,
        loading_users_success: false,
        loading_users_failed: true,
      };
    default:
      return state;
  }
}
