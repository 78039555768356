// core components
// import GridItem from "components/Grid/GridItem.js";
import React, { useState } from "react";
import { Button, Container, Grid, makeStyles } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
// Formik form
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody";
import { useSelector } from "react-redux";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  first_name: yup
    .string("Saler first name")
    .required("Saler first name is required"),
  last_name: yup
    .string("Saler last name")
    .required("Saler last name is required"),
  email: yup.string("Saler email").required("Saler email is required"),
});

export default function NewSaler() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth.auth);
  const [isCreating, setIscreating] = useState(false);
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIscreating(true);
      const datas = {
        ...values,
        created_by: user.id,
        password: "P@ssw0rd123",
        password2: "P@ssw0rd123",
        is_superuser: false,
        is_admin: false,
        is_shop_owner: false,
      };
      console.log(JSON.stringify(values, null, 2));
      API.post(URLS.USERS.SALER.CREATE, datas)
        .then((resp) => {
          const { data } = resp;
          toast.success(data.detail);
          setIscreating(false);
        })
        .catch((error) => {
          toast.error(
            error.response.data.detail ?? "Saler registration error!!"
          );
          setIscreating(false);
        });
    },
  });
  return (
    <GridContainer>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{t("New_Saler")}</h4>
          <p className={classes.cardCategoryWhite}>
            You are creating a new saler profile
          </p>
        </CardHeader>
        <CardBody>
          <Container>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="first_name"
                    name="first_name"
                    label={t("First_Name")}
                    variant="outlined"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.first_name &&
                      Boolean(formik.errors.first_name)
                    }
                    helperText={
                      formik.touched.first_name && formik.errors.first_name
                    }
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="last_name"
                    name="last_name"
                    label={t("Last_Name")}
                    variant="outlined"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.last_name &&
                      Boolean(formik.errors.last_name)
                    }
                    helperText={
                      formik.touched.last_name && formik.errors.last_name
                    }
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="phone_number"
                    name="phone_number"
                    label={t("Phone_Number")}
                    variant="outlined"
                    value={formik.values.phone_number}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phone_number &&
                      Boolean(formik.errors.phone_number)
                    }
                    helperText={
                      formik.touched.phone_number && formik.errors.phone_number
                    }
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    variant="outlined"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item md={6}></Grid>
                <Grid item md={12}>
                  <Button
                    disabled={isCreating}
                    type={"submit"}
                    variant="contained"
                    color="primary"
                  >
                    {t("Create_Saler")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
