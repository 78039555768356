import { UPDATE_ACCOUNT_PASSWORD_FAILED } from "store/actions/UpdatePasswordAction";
import { UPDATE_ACCOUNT_PASSWORD_SUCCESS } from "store/actions/UpdatePasswordAction";
import { UPDATE_ACCOUNT_PASSWORD_REQUEST } from "store/actions/UpdatePasswordAction";

const initialState = {
  update_account_password_request: false,
  update_account_password_success: false,
  update_account_password_failed: false,
  user: "",
};

export function updatePasswordReducers(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ACCOUNT_PASSWORD_REQUEST:
      return {
        ...state,
        update_account_password_request: true,
        update_account_password_success: false,
        update_account_password_failed: false,
      };
    case UPDATE_ACCOUNT_PASSWORD_SUCCESS:
      return {
        ...state,
        update_account_password_request: false,
        update_account_password_success: true,
        update_account_password_failed: false,
        user: action.payload,
      };
    case UPDATE_ACCOUNT_PASSWORD_FAILED:
      return {
        ...state,
        update_account_password_request: false,
        update_account_password_success: false,
        update_account_password_failed: true,
      };
    default:
      return state;
  }
}
